import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Bgheader from "../../Components/BgHeader";
import "./faq.css";
import RightBar from "./faq-rightBar/RightBar";
import InfoBeeLoc from "./faq-content/InfoBeeLoc";
import { useLocation } from "react-router-dom";

const Faqs = () => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  document.title = "Faqs";
  return (
    <div>
      <Navbar />
      <Bgheader heading="FAQS" />
      <div className="container_page">
        <RightBar />
        <InfoBeeLoc />
      </div>

      <Footer />
    </div>
  );
};

export default Faqs;
