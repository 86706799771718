import { TextareaAutosize, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import Rating from "@mui/material/Rating";
import "./FormAvis.css";
import { CreateAvisCar, getAvisCar } from "../../../Services/Services";
import "./FormAvisValidationSchema";
import { FormAvisValidationSchema } from "./FormAvisValidationSchema";

export default function FormAvis({ voiture_id, setAvis, avisCar }) {
  return (
    <div className="section-avis-voiture">
      <div>
        {" "}
        <h2 className="formulaire-avis">Formulaire d'avis clients</h2>
      </div>

      <Formik
        initialValues={{
          nom: "",
          email: "",
          commentaire: "",
          etoiles: 0,
        }}
        validationSchema={FormAvisValidationSchema}
        onSubmit={(values) => {
          CreateAvisCar({ ...values, voiture_id: voiture_id });
          setAvis([...avisCar, { ...values, voiture_id: voiture_id }]);
          values.nom = "";
          values.email = "";
          values.commentaire = "";
          values.etoiles = "";
        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          errors,
          touched,
        }) => (
          <form action="" className="form-Avis" onSubmit={handleSubmit}>
            <Typography component="legend">Votre note</Typography>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1vh" }}
            >
              <Rating
                name="etoiles"
                value={Number(values.etoiles)}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <p
                style={{
                  color: "red",
                  fontSize: 13,
                }}
                className="error-paragraph"
              >
                {errors.etoiles && touched.etoiles && errors.etoiles}
              </p>
            </div>

            <div className="avis-info">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "1vh",
                }}
              >
                <TextField
                  label="Nom"
                  style={{ flex: 1 }}
                  name="nom"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.nom}
                />
                <p
                  style={{
                    color: "red",
                    fontSize: 13,
                  }}
                  className="error-paragraph"
                >
                  {errors.nom && touched.nom && errors.nom}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "1vh",
                }}
              >
                <TextField
                  label="Email"
                  type="email"
                  style={{ flex: 1 }}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                />
                <p
                  style={{
                    color: "red",
                    fontSize: 13,
                  }}
                  className="error-paragraph"
                >
                  {errors.email && touched.email && errors.email}
                </p>
              </div>
            </div>

            <TextareaAutosize
              minRows={5}
              name="commentaire"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.commentaire}
            />
            <p
              style={{
                color: "red",
                fontSize: 13,
              }}
              className="error-paragraph"
            >
              {errors.commentaire && touched.commentaire && errors.commentaire}
            </p>

            <button
              style={{
                padding: "2vh 18px",
                backgroundColor: "#e2ad2b",
                border: "none",
                color: "#fff",
                maxWidth: "400px",
                margin: "auto",
              }}
              type="submitt"
              className="soummettre"
            >
              Envoyer
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}
