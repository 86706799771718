import { Avatar } from "@mui/material";
import React from "react";
import Carousel from "react-elastic-carousel";
import "./Caroussel.css";
import fille1 from "../../assets/fille1.jpg";
import fille2 from "../../assets/fille2.jpg";
import fille3 from "../../assets/fille3.jpg";
import user from "../../assets/user.jpg";
export default function CarousselAvis() {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];
  const [items, setItems] = React.useState([
    {
      id: 1,
      image: fille1,
      texte: "J'ai été ravie d'avoir eu recours aux services de beeloc",
      nom: "Naomie",
    },
    {
      id: 2,
      image: fille3,
      texte: "Ce fut une superbe experience avec beeloc. ",
      nom: "Fadila",
    },
    {
      id: 3,
      image: fille3,
      texte: "Des voitures de bonnes qualité a prix extraordinaire",
      nom: "Chantal",
    },
    {
      id: 4,
      image: user,
      texte: "Le personnel est tres poli, tres respectueux ",
      nom: "Joseph",
    },
    {
      id: 5,
      image: fille1,
      texte: "Je reviendrais c'est sur car j'ai vraimé aimé",
      nom: "Naima",
    },
  ]);

  return (
    <div>
      {" "}
      <div className="carousel-wrapper">
        <Carousel breakPoints={breakPoints}>
          {items.map((item) => (
            <div className="item-avis" key={item.id}>
              <Avatar src={item.image} sx={{ width: "70px", height: "70px" }} />
              <span
                style={{
                  fontSize: 16,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {item.nom}
              </span>
              <span style={{ fontSize: 16, textAlign: "center" }}>
                {item.texte}
              </span>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
