import {
  Checkbox,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RowRadioButtonsGroup from "../../UI/Inputs/TypeRadios/TypeRadios";
import { TextareaAutosize } from "@mui/base";
import "./FormulaireReservation.css";
import FormCarteCredit from "../../UI/Forms/FormCarteCredit";
import FormCarteStripe from "../../UI/Forms/FormCarteStripe";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { Formik } from "formik";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  AppliquerCoupon,
  Paiement,
  Reservation,
} from "../../Services/Services";
import { Navigate, useNavigate } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import { FormulaireReservationValidationSchema } from "./FormulaireRervationValidation";
import { notify } from "../../Services/fonctions";
export default function FormulaireReservation() {
  const [afficheBtn, setAfficheBtn] = useState(true);
  const stripe = useStripe();
  //mettre dans les VE

  //Permet d'initialiser l'objet stripe
  const [coupon, setCoupon] = useState(0);
  const stripeTestPromise = loadStripe(process.env.REACT_APP_Public);
  const navigate = useNavigate();
  //permet d'avoir acces aux éléments de la carte
  const elements = useElements();
  const today = new Date().toISOString().split("T")[0];
  const minTime = new Date().toTimeString().slice(0, 5);
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;
  const informationsReservation = localStorage.getItem(
    "informationsReservation"
  )
    ? JSON.parse(localStorage.getItem("informationsReservation"))
    : {};
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [etatsAfficheForms1, setEtatAfficheForms1] = React.useState(true);
  const [etatsAfficheForms2, setEtatAfficheForms2] = React.useState(false);
  const labels = ["M.", "Ms"];
  const modePaiement = ["Carte de crédit", "especes", "Stripe"];
  const [prix, setPrix] = useState(JSON.parse(localStorage.voiture).prix);
  const [date1, setDate1] = useState();
  const [date2, setDate2] = useState();

  return (
    <div className="formulaire-reservation">
      <Formik
        validationSchema={FormulaireReservationValidationSchema}
        initialValues={{
          lieuCharge: informationsReservation
            ? informationsReservation.lieu
            : "",
          dateDebut: informationsReservation
            ? informationsReservation.dateRamassage
            : today,
          heureDebut: informationsReservation
            ? informationsReservation.heureRamassage
            : "",
          locRetour: informationsReservation
            ? informationsReservation.lieuRetourVoiture
            : "",
          dateFin: informationsReservation
            ? informationsReservation.dateRetour
            : today,
          heureDFin: informationsReservation
            ? informationsReservation.heureRetour
            : "",
          assurance: "basic",
          supplementaire: "chauffeur",
          payement: "carte",
          coupon: "",
        }}
        onSubmit={async (values) => {
          //génération du token
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
          });

          if (!error) {
            const { id } = paymentMethod;

            const amount =
              localStorage.voiture && values.dateDebut && values.dateFin
                ? Math.abs(
                    JSON.parse(localStorage.voiture).prix *
                      ((new Date(values.dateFin) - new Date(values.dateDebut)) /
                        (1000 * 3600 * 24) +
                        1)
                  ) -
                  Math.abs(
                    (JSON.parse(localStorage.voiture).prix *
                      ((new Date(values.dateFin) - new Date(values.dateDebut)) /
                        (1000 * 3600 * 24) +
                        1) *
                      coupon) /
                      100
                  )
                : 0;
            const result = await Paiement(id, amount * 100);
            if (result.status === 200) {
              notify("paiement reussi");
              localStorage.getItem("informationsReservation") &&
                localStorage.setItem(
                  "informationsReservation",
                  JSON.stringify({})
                );
              const infos = {
                ...values,
                user_id: JSON.parse(localStorage.getItem("user_id")),
                voiture_id: JSON.parse(localStorage.getItem("voiture")).id,
                total: amount,
              };
              const result = await Reservation(infos);

              navigate("/");
            } else {
              notify("Un probleme es survenu");
            }
          } else {
            notify("Les informations de la carte!");
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          touched,
        }) => (
          <form action="" className="form-reservation" onSubmit={handleSubmit}>
            <div className="inputs-fields">
              <div className="lieu" style={{ flex: 2 }}>
                <label htmlFor="" className="label-lieu-reservation">
                  Lieu de prise en charge :
                </label>

                <TextField
                  placeholder="Lieu de ramassage"
                  sx={{
                    background: "#fff",
                    borderRadius: "4px",
                    outline: "none",
                    border: "none",
                    marginTop: "1.5vh",
                  }}
                  size="small"
                  fullWidth={true}
                  value={values.lieuCharge}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="lieuCharge"
                />

                <p
                  style={{
                    color: "red",
                    fontSize: 13,
                    marginTop: "1vh",
                  }}
                  className="error-paragraph"
                >
                  {errors.lieuCharge && touched.lieuCharge && errors.lieuCharge}
                </p>
              </div>

              <div className="heure" style={{ flex: 1 }}>
                <label htmlFor="" className="label-heure-reservation">
                  Date de ramassage :
                </label>
                <TextField
                  type="date"
                  sx={{
                    background: "#fff",
                    borderRadius: "4px",
                    outline: "none",
                    border: "none",
                    marginTop: "1.5vh",
                  }}
                  inputProps={{
                    min: today,
                  }}
                  size="small"
                  value={values.dateDebut}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth={true}
                  name="dateDebut"
                />
                <p
                  style={{
                    color: "red",
                    fontSize: 13,
                    marginTop: "1vh",
                  }}
                  className="error-paragraph"
                >
                  {errors.dateDebut && touched.dateDebut && errors.dateDebut}
                </p>
              </div>

              <div className="date" style={{ flex: 1 }}>
                <label htmlFor="" className="label-date-reservation">
                  Heure:
                </label>
                <TextField
                  placeholder="Heure de ramassage"
                  sx={{
                    background: "#fff",
                    borderRadius: "4px",
                    outline: "none",
                    border: "none",
                    marginTop: "1.5vh",
                  }}
                  value={values.heureDebut}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                  fullWidth={true}
                  type="time"
                  name="heureDebut"
                />
                <p
                  style={{
                    color: "red",
                    fontSize: 13,
                    marginTop: "1vh",
                  }}
                  className="error-paragraph"
                >
                  {errors.heureDebut && touched.heureDebut && errors.heureDebut}
                </p>
              </div>
            </div>
            <div className="inputs-fields"></div>
            <div className="inputs-fields decalage">
              <div className="lieu" style={{ flex: 2 }}>
                <label htmlFor="" className="label-lieu-reservation">
                  Lieu du retour :
                </label>
                <TextField
                  placeholder="Lieu de retour"
                  sx={{
                    background: "#fff",
                    borderRadius: "4px",
                    outline: "none",
                    border: "none",
                    marginTop: "1.5vh",
                  }}
                  size="small"
                  fullWidth={true}
                  value={values.locRetour}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="locRetour"
                />
                <p
                  style={{
                    color: "red",
                    fontSize: 13,
                    marginTop: "1vh",
                  }}
                  className="error-paragraph"
                >
                  {errors.locRetour && touched.locRetour && errors.locRetour}
                </p>
              </div>

              <div className="heure" style={{ flex: 1 }}>
                <label htmlFor="" className="label-heure-reservation">
                  Date de retour :
                </label>
                <TextField
                  type="date"
                  placeholder="pays,ville ou code aéroport"
                  sx={{
                    background: "#fff",
                    borderRadius: "4px",
                    outline: "none",
                    border: "none",
                    marginTop: "1.5vh",
                  }}
                  inputProps={{
                    min: today,
                  }}
                  size="small"
                  fullWidth={true}
                  value={values.dateFin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="dateFin"
                />
                <p
                  style={{
                    color: "red",
                    fontSize: 13,
                    marginTop: "1vh",
                  }}
                  className="error-paragraph"
                >
                  {errors.dateFin && touched.dateFin && errors.dateFin}
                </p>
              </div>

              <div className="date" style={{ flex: 1 }}>
                <label htmlFor="" className="label-date-reservation">
                  Heure:
                </label>
                <TextField
                  placeholder="Heure de ramassage"
                  sx={{
                    background: "#fff",
                    borderRadius: "4px",
                    outline: "none",
                    border: "none",
                    marginTop: "1.5vh",
                  }}
                  size="small"
                  fullWidth={true}
                  type="time"
                  value={values.heureDFin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="heureDFin"
                />
                <p
                  style={{
                    color: "red",
                    fontSize: 13,
                    marginTop: "1vh",
                  }}
                  className="error-paragraph"
                >
                  {errors.heureDFin && touched.heureDFin && errors.heureDFin}
                </p>
              </div>
            </div>
            <Divider />
            <div id="partie-assurance">
              <h3 className="assurance">Assurance :</h3>
              <div className="checkboxes">
                <div className="checkbox-droite" style={{ flex: 2 }}>
                  <div>
                    <Radio
                      checked={values.assurance === "basic"}
                      value="basic"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="assurance"
                      inputProps={{ "aria-label": "A" }}
                    />
                    <label htmlFor="" className="choix-nombre">
                      Basic
                    </label>
                  </div>
                  <div>
                    <Radio
                      checked={values.assurance === "full"}
                      value="full"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="assurance"
                      inputProps={{ "aria-label": "A" }}
                    />
                    <label htmlFor="" className="choix-nombre">
                      Full
                    </label>
                  </div>
                </div>
                <div className="checkbox-gauche" style={{ flex: 2 }}>
                  <Radio
                    checked={values.assurance === "intermediare"}
                    value="intermediare"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="assurance"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <label htmlFor="" className="choix-nombre">
                    Intermediaire
                  </label>
                </div>
              </div>
            </div>
            <div id="partie-options-sup">
              <h3 className="options-sup">Option supplémentaire :</h3>
              <div className="checkboxes">
                <div className="checkbox-droite" style={{ flex: 2 }}>
                  <div>
                    <Radio
                      checked={values.supplementaire === "chauffeur"}
                      value="chauffeur"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="supplementaire"
                      inputProps={{ "aria-label": "A" }}
                    />
                    <label htmlFor="" className="choix-nombre">
                      service chauffeur
                    </label>
                  </div>
                </div>
                <div className="checkbox-gauche" style={{ flex: 2 }}>
                  <Radio
                    checked={values.supplementaire === "équipementHiver"}
                    value="équipementHiver"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="supplementaire"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <label htmlFor="" className="choix-nombre">
                    équipement d’hiver
                  </label>
                </div>
              </div>
            </div>
            <Divider />
            {!token ? (
              <div>
                <div id="partie-details-client">
                  <h3 className="details-client">Détails client :</h3>
                  <div id="radios">
                    <RowRadioButtonsGroup labels={labels} />
                  </div>
                </div>
                <div id="form-details">
                  <div
                    className="inputs-fields"
                    style={{ marginBottom: "0vh" }}
                  >
                    <div style={{ flex: 2 }}>
                      <TextField
                        placeholder="Prénom"
                        sx={{
                          background: "#fff",
                          borderRadius: "4px",
                          outline: "none",
                          border: "none",
                          marginTop: "1.5vh",
                        }}
                        size="small"
                        fullWidth={true}
                      />
                    </div>
                    <div style={{ flex: 2 }}>
                      <TextField
                        placeholder="Nom"
                        sx={{
                          background: "#fff",
                          borderRadius: "4px",
                          outline: "none",
                          border: "none",
                          marginTop: "1.5vh",
                        }}
                        size="small"
                        fullWidth={true}
                      />
                    </div>
                  </div>
                  <div className="inputs-fields">
                    <div style={{ flex: 2 }}>
                      <TextField
                        type="email"
                        placeholder="Email"
                        sx={{
                          background: "#fff",
                          borderRadius: "4px",
                          outline: "none",
                          border: "none",
                          marginTop: "1.5vh",
                        }}
                        size="small"
                        fullWidth={true}
                      />
                    </div>

                    <div style={{ flex: 2 }}>
                      <TextField
                        placeholder="Téléphone"
                        sx={{
                          background: "#fff",
                          borderRadius: "4px",
                          outline: "none",
                          border: "none",
                          marginTop: "1.5vh",
                        }}
                        size="small"
                        fullWidth={true}
                        type="telephone"
                      />
                    </div>
                  </div>
                  <div className="inputs-fields">
                    <TextareaAutosize minRows={5} style={{ width: "100%" }} />
                  </div>
                </div>
              </div>
            ) : null}

            <Divider />
            <div id="partie-mode-payement">
              <h4 className="mode-payement">Mode de payement :</h4>
              <div id="radios">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    checked={values.payement === "carte"}
                    value="carte de credit"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="payement"
                    control={<Radio />}
                    label="carte de credit"
                    onClick={() => setEtatAfficheForms1(true)}
                  />
                </RadioGroup>
              </div>
            </div>

            {/* <div id="carte-credit">
        <h5 className="numero-carte">NUMÉRO DE CARTE DE CRÉDIT</h5>
        <span className="numero">**** **** **** 5634</span>
        <div className="details-carte">
          <div className="nom-propietaire" id="nom-proprietaire">
            <span className="nom-proprietaire">NOM DU PROPRIÉTAIRE</span>
            <span className="">Amadou</span>
          </div>
          <div className="validation-carte" id="validation">
            <span className="validation">VALIDE JUSQU'À</span>
            <span>03 / 2025</span>
          </div>
          <div className="code-cvv" id="code-cvv">
            <span className="code-cvv">CODE CVV</span>
            <span>345</span>
          </div>
        </div>
      </div>*/}
            <div>
              <div style={{ marginTop: "1vh" }}>
                {etatsAfficheForms1 && (
                  <CardElement
                    options={{ hidePostalCode: true }}
                    id="card-element"
                  />
                )}
              </div>
            </div>
            {values.dateDebut && values.dateFin && (
              <div
                id="coupon"
                style={{
                  display: "flex",
                  marginTop: "5vh",
                  alignItems: "center",
                  gap: 10,
                  flexWrap: "wrap",
                }}
              >
                <TextField
                  placeholder="Coupon réduction"
                  sx={{
                    background: "#fff",
                    borderRadius: "4px",
                    outline: "none",
                    border: "none",
                  }}
                  value={values.coupon}
                  onChange={handleChange}
                  name="coupon"
                  onBlur={handleBlur}
                  size="small"
                />
                {afficheBtn ? (
                  <span
                    className="resever-boutton"
                    style={{ background: "#e2ad2b", border: "none" }}
                    id="boutton-coupon"
                    onClick={async () => {
                      await AppliquerCoupon(
                        values.coupon,
                        setCoupon,
                        setAfficheBtn
                      );
                    }}
                  >
                    Appliquer Coupon
                  </span>
                ) : (
                  <VerifiedIcon sx={{ color: "#90EE90", fontSize: 30 }} />
                )}
              </div>
            )}

            <div id="total-carte">
              <span>Montant d'origine</span>
              <span>
                {localStorage.voiture && values.dateDebut && values.dateFin
                  ? Math.abs(
                      JSON.parse(localStorage.voiture).prix *
                        ((new Date(values.dateFin) -
                          new Date(values.dateDebut)) /
                          (1000 * 3600 * 24) +
                          1)
                    ) + "€"
                  : 0 + "€"}
              </span>
            </div>
            {coupon !== 0 && (
              <div id="total-carte">
                <span>Pourcentage coupon:</span>
                <span>{coupon}%</span>
              </div>
            )}

            <div id="total-carte">
              <span>Montant Réduction:</span>
              <span>
                {localStorage.voiture && values.dateDebut && values.dateFin
                  ? Math.abs(
                      (JSON.parse(localStorage.voiture).prix *
                        ((new Date(values.dateFin) -
                          new Date(values.dateDebut)) /
                          (1000 * 3600 * 24) +
                          1) *
                        coupon) /
                        100
                    )
                  : coupon}
                €
              </span>
            </div>
            <Divider sx={{ marginTop: "4vh" }} />
            <div id="total-carte">
              <h5>Total</h5>
              <span>
                {localStorage.voiture && values.dateDebut && values.dateFin
                  ? Math.abs(
                      JSON.parse(localStorage.voiture).prix *
                        ((new Date(values.dateFin) -
                          new Date(values.dateDebut)) /
                          (1000 * 3600 * 24) +
                          1)
                    ) -
                    Math.abs(
                      (JSON.parse(localStorage.voiture).prix *
                        ((new Date(values.dateFin) -
                          new Date(values.dateDebut)) /
                          (1000 * 3600 * 24) +
                          1) *
                        coupon) /
                        100
                    )
                  : 0}
                €
              </span>
            </div>
            <div id="reservation-boutton-partie">
              <div id="reservation-boutton">
                <button
                  type="submit"
                  className="resever-boutton"
                  style={{ background: "#e2ad2b", border: "none" }}
                >
                  Réservez maintenant
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
