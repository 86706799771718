import React from "react";
import "./ServiceCard.css";
export default function ServiceCard({ icon, titre, description }) {
  return (
    <div className="service">
      <div>{icon}</div>
      <p className="service-title" style={{ color: "#fff", fontSize: "12px" }}>
        {titre}
      </p>
      <p
        className="service-description2"
        style={{ color: "#fff", marginTop: "1vh" }}
      >
        {description}
      </p>
    </div>
  );
}
