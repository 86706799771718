import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function RowRadioButtonsGroup({
  labels,
  setEtatAfficheForms1,
  setEtatAfficheForms2,
}) {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          value={labels[0]}
          control={<Radio />}
          label={labels[0]}
          onClick={() => {
            setEtatAfficheForms1(true);
            setEtatAfficheForms2(false);
          }}
        />
        <FormControlLabel
          value={labels[2]}
          control={<Radio />}
          label={labels[1]}
          onClick={() => {
            setEtatAfficheForms1(false);
            setEtatAfficheForms2(false);
          }}
        />
       
      </RadioGroup>
    </FormControl>
  );
}
