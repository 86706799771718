import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export function renitialiseFiltres(
  setTypeBoite,
  setMarque,
  setTypeModele,
  setCouleur,
  setTypeCarburant,
  setInformationsReservation,
  setAnnee,
  setCheckedPlaces,
  setIsCheckbox2Checked,
  setIsCheckbox3Checked,
  setIsCheckbox4Checked,
  setIsCheckbox5Checked,
  setFilteredCars,
  allCars
) {
  setTypeBoite("");
  setMarque("");
  setTypeModele("");
  setCouleur("");
  setTypeCarburant("");
  setInformationsReservation({ prix: 800 });
  setAnnee("");
  setCheckedPlaces([]);
  setIsCheckbox2Checked(false);
  setIsCheckbox3Checked(false);
  setIsCheckbox4Checked(false);
  setIsCheckbox5Checked(false);
  setFilteredCars(allCars);
}
export const notify = (message) =>
  toast(message, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
