import "./FooterStyle.css";
import logo2 from "../assets/logo.svg";
import React from "react";
import {
  FaChevronRight,
  FaClock,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaSearchLocation,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-container">
          <div className="footer-col-1">
            <div className="info-footer">
              <Link to={"/"}>
                <img src={logo2} alt="BeeLoc" width="140"></img>
              </Link>

              <p>
                {" "}
                Bienvenue chez BeeLoc, votre agence de location de voiture de
                confiance. Nous sommes fiers de vous offrir un service de
                qualité supérieure et une expérience de location inégalée.
              </p>
              <div className="infos-data">
                <FaChevronRight
                  size={16}
                  style={{ marginRight: "9px", color: "#090909" }}
                />
                <div>
                  <span>
                    <Link to={"/#"}>Données personnelle</Link>
                  </span>
                </div>
              </div>

              <div className="infos-faqs">
                <FaChevronRight
                  size={16}
                  style={{ marginRight: "9px", color: "#090909" }}
                />
                <div>
                  <span>
                    <Link to={"/#"}>Faqs</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-col-2">
            <div className="Info-contact">
              <h3>Contactez nous:</h3>
              <div className="adresse-footer">
                <FaMapMarkerAlt
                  style={{ marginRight: "9px", color: "#090909" }}
                />
                <div>
                  <span>
                    <b>Adresse du siège social :</b>
                  </span>
                  <span> Adresse adr Phasellus sagittis.</span>
                </div>
              </div>

              <div className="horloge">
                <FaClock style={{ marginRight: "9px", color: "#090909" }} />
                <div>
                  <span> 9:00 - 17:30</span>
                </div>
              </div>

              <div className="email-footer">
                <FaEnvelope style={{ marginRight: "9px", color: "#090909" }} />
                <div>
                  <span>BeeLoc@gmail.com</span>
                </div>
              </div>

              <div className="phone-footer">
                <FaPhoneAlt style={{ marginRight: "9px", color: "#090909" }} />
                <div>
                  <span> 0606060606</span>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-col-3">
            <div className="Info-info">
              <h3>Informations:</h3>

              <div className="infos-cgl">
                <FaChevronRight
                  size={16}
                  style={{ marginRight: "9px", color: "#090909" }}
                />
                <div>
                  <span>
                    <Link to={"/#"}>CGL</Link>
                  </span>
                </div>
              </div>

              <div className="infos-mention">
                <FaChevronRight
                  size={16}
                  style={{ marginRight: "9px", color: "#090909" }}
                />
                <div>
                  <span>
                    <Link to={"/#"}>Mention Légales</Link>
                  </span>
                </div>
              </div>

              <div className="infos-générales">
                <FaChevronRight
                  size={16}
                  style={{ marginRight: "9px", color: "#090909" }}
                />
                <div>
                  <span>
                    <Link to={"/#"}>Informations Générales</Link>
                  </span>
                </div>
              </div>

              <div className="infos-Aide">
                <FaChevronRight
                  size={16}
                  style={{ marginRight: "9px", color: "#090909" }}
                />
                <div>
                  <span>
                    <Link to={"/aide"}>Aide</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-2">
        <div className="footer-2-container">
          <div className="footer-2-col-1">
            <span>Copyright©2023. BeeLoc</span>
          </div>
          <div className="footer-2-col-2">
            <Link to={"/#"}>
              <FaFacebook
                size={20}
                style={{ marginRight: "10px", color: "#fff" }}
              />
            </Link>
            <Link to={"/#"}>
              <FaTwitter
                size={20}
                style={{ marginRight: "10px", color: "#fff" }}
              />
            </Link>
            <Link to={"/#"}>
              <FaInstagram
                size={20}
                style={{ marginRight: "10px", color: "#fff" }}
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
