import { Container, Typography } from "@mui/material";
import React from "react";
import CarPromotionCard from "../CarPromotionCard/CarPromotionCard";
import PaginationCard from "../PaginationCars/PaginationCard";
import TabsAccueil from "../TabsAcceil/TabsAccueil";
import "./SectionPromotion.css";

import MyCircularProgress from "../../UI/CircularProgress/CircularProgress";

//import "../../Mock/Mock"
export default function SectionPromotion({
  allCars,
  carsPerPage,
  carsPerTypes,
  uniqueTypes,
  setCarsPerTypes,
  postsParPage,
  currentPage,
  setCurrentPage,
  loading,
}) {
  return (
    <section>
      <div className="promotion">
        <div className="tiret-droit-promotion"></div>
        <h2 className="text-center titre-promotion">Nos Véhicules</h2>
        <div className="tiret-gauche-promotion"></div>
      </div>
      <span className="promotion-texte">
        Découvrez nos nouveaux modèles de voiture et faites votre choix
      </span>
      <TabsAccueil
        uniqueTypes={uniqueTypes}
        allCars={allCars}
        carsPerTypes={carsPerTypes}
        setCarsPerTypes={setCarsPerTypes}
        setCurrentPage={setCurrentPage}
      />
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 3,
          marginBottom: "5vh",
        }}
      >
        {loading && <MyCircularProgress />}
        {carsPerTypes.length !== 0 ? (
          carsPerPage.map((car, index) => (
            <CarPromotionCard car={car} key={index} />
          ))
        ) : (
          <span>Aucune voiture pour l'instant</span>
        )}
      </Container>
      {carsPerTypes.length !== 0 && (
        <PaginationCard
          totalCars={carsPerTypes.length}
          postsPerPage={postsParPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      )}
    </section>
  );
}
