/** Partie Services
 * Tout appel vers l'API
 */

import axios from "axios";
import { Navigate, redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { apiInstance } from "./AxiosConfig";
import { notify } from "./fonctions";
/** Liste de toutes les voitures */
export async function getAllCars(setAllCars, setLoading) {
  const result = await apiInstance.get("/beeloc/voitures");
  setAllCars(result.data);
  setLoading(false);
}
/** Liste de toutes les marques*/
export async function getAllMarques(setAllCars) {
  const result = await apiInstance.get("/beeloc/marques");
  setAllCars(result.data);
}
/** Liste de toutes les voitures par types*/
export async function getAllCarsPerTypes(typeVoiture, setCarsPerTypes) {
  const result = await apiInstance.get(`/beeloc/voitures_type/${typeVoiture}`);
  setCarsPerTypes(result.data);
}
/** Liste de toutes les voitures par marques*/
export async function getAllCarsPerMarques(marque, setCarsPerMarques) {
  const result = await apiInstance.get(`/beeloc/voitures_marque/${marque}`);
  setCarsPerMarques(result.data);
}
/** Liste de toutes les voitures par villes*/
export async function getAllCarsPerVilles(ville, setCarsPerVilles, setLoading) {
  const result = await apiInstance.get(`/beeloc/voitures_ville/${ville}`);
  setCarsPerVilles(result.data);
  setLoading(false);
}
/** Detail d'une voiture */
export async function getOneCar(setOneCar, id, setLoading) {
  try {
    const result = await apiInstance.get(`/beeloc/voiture/${id}`);
    setOneCar(result.data);
    setLoading(false);
  } catch (err) {
    console.error(err);
  }
}
/** Récuperation de tous les types de voiture*/
export async function getAllTypesCars(setOneCar, id) {
  const result = await apiInstance.get(`/beeloc/voiture/${id}`);
  setOneCar(result.data);
}
/** Récuperation de toutes les villes*/
export async function getAllVilles(setAllVilles) {
  const result = await apiInstance.get(`/beeloc/villes`);
  setAllVilles(result.data);
}
/** Incription d'un client */
export async function register(infosClient, setLoading) {
  const result = await apiInstance
    .post("/register", infosClient)
    .catch((err) => {
      if (err.response.status === 422) {
        notify("Cet adresse email est deja associé a un compte");
      }
      setLoading(false);
      console.error(err);
    });

  return result;
}
/** Send email renitialisation */
export async function SendEmail(email) {
  const result = await apiInstance
    .post("/password/email", email)
    .catch((err) => {
      console.error(err);
    });

  return result;
}
/** Login d'un client */
export async function login(infosClient, setLoading) {
  /*
  const result = await apiInstance.post("/login", infosClient).catch((err) => {
    if (err.response.status === 401) {
      alert("Login ou mot de passe incorrect");
    }
  });
  if (result.status === 200) {
    localStorage.setItem("user_id", JSON.stringify(result.data.client.id));
    localStorage.setItem("token", result.data.message);
  }*/
  try {
    const result = await apiInstance.post("/login", infosClient);
    localStorage.setItem("user_id", JSON.stringify(result.data.id));
    localStorage.setItem("token", result.data.api_token);
    return result;
  } catch (err) {
    console.error(err);
    if (err.response.status === 401) {
      setLoading(false);
      notify("Login ou mot de passe incorrect");
    }
  }
}
/** Compte d'un client */
export async function CompteClient(id, setUser) {
  const result = await apiInstance.get(
    //a metrre dans les VE
    `/client/${id}`
  );
  setUser(result.data);
}
/** Avoir avis d'une voiture */
export async function getAvisCar(setAvisCar, id) {
  const result = await apiInstance.get(
    //a metrre dans les VE
    `/beeloc/avis_voiture/${id}`
  );
  setAvisCar(result.data);
}
/** Creation avis voiture */
export async function CreateAvisCar(infosCar) {
  const result = await apiInstance.post(
    //a metrre dans les VE
    "/beeloc/createAvis",
    infosCar
  );
}
/**Logout*/
export async function Logout(setToken) {
  const result = await apiInstance.post(
    //a metrre dans les VE
    "/logout"
  );
  if (result.status === 200) {
    localStorage.clear();
    setToken(false);
  }
  return result;
}
/** Get All types */
export async function AllTypesCars(setTypeCars) {
  const result = await apiInstance.get(
    //a metrre dans les VE
    `/beeloc/types`
  );
  setTypeCars(result.data);
}
/**Reservations d'un client */
export async function AllReservatio(setTypeCars, id, setLoading) {
  const result = await apiInstance.get(
    //a metrre dans les VE
    `/client/reservations/${id}`
  );
  setTypeCars(result.data);

  setLoading(false);
}
/**Edit client */
export async function EditClient(infosClient, id) {
  const result = await apiInstance
    .post(`/client/update/${id}`, infosClient)
    .catch((err) => {
      notify("Une erreur es survenue. Veuillez reessayer plus tard");
      console.error(err);
    });

  return result;
}
/*Paiement bancaire*/
export async function Paiement(id, amount) {
  try {
    const response = await axios.post("http://agence.bee-loc.com/api/paiement", {
      source: id,
      amount: amount,
    });
    return response;
  } catch (err) {
    console.error(err);
  }
}
/*Reserver voiture*/
export async function Reservation(infosReservation) {
  const result = await apiInstance.post(
    "/createReservations",
    infosReservation
  );
}
/*Appliquer Coupon*/
export async function AppliquerCoupon(code, setCoupon, setAfficheBtn) {
  try {
    const result = await apiInstance.get(`/isValidCoupon/${code}`);
    if (result.data !== "") {
      setCoupon(result.data.pourcentage_off);
      setAfficheBtn(false);
    } else {
      notify("le coupon a expiré");
    }
  } catch (e) {
    notify("le coupon n'es pas valide!");
  }
}
