import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import EmailIcon from "@mui/icons-material/Email";
import GroupIcon from "@mui/icons-material/Group";
import TranslateIcon from "@mui/icons-material/Translate";
import PaymentIcon from "@mui/icons-material/Payment";
import businessBoy from "../../assets/madame.png";
import "./SectionDetailsVoiture.css";
import StarIcon from "@mui/icons-material/Star";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import { Avatar, Box, Divider } from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PaletteIcon from "@mui/icons-material/Palette";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import CarrousselVoiture from "../CarrousselVoiture/CarrousselVoiture";
import FormAvis from "../../UI/Forms/FormAvis/FormAvis";
import ModalCommentaires from "../../UI/ModalCommentaires/ModalCommentaire";
import MyCircularProgress from "../../UI/CircularProgress/CircularProgress";

export default function SectionDetailsVoiture({
  car,
  avisCar,
  voiture_id,
  setAvis,
  open,
  handleOpen,
  handleClose,
  setOpen,
  twoLastAvis,
  loading,
}) {
  const tabNumbers = [0, 1, 2, 3, 4];
  const token = localStorage.getItem("token");
  let tabrating = avisCar.map((avis) => avis.etoiles);
  let sommeRating = 0;

  for (let index = 0; index < avisCar.length; index++) {
    sommeRating = sommeRating + Number(avisCar[index].etoiles);
  }

  let moy = sommeRating / tabrating.length;
  let rating = tabrating.length !== 0 ? moy : 0;

  let numbers = [];
  return (
    <section id="details_voitures">
      <ModalCommentaires
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleOpen={handleOpen}
        avisCar={avisCar}
      />
      {loading ? (
        <MyCircularProgress />
      ) : (
        <div id="section-image">
          <div className="entete-details">
            <h3 className="">{car.nom}</h3>
            <div className="details-entete">
              <div></div>
              <div className="rating-entete">{Math.round(rating)}/5</div>
            </div>
          </div>
          <div
            className="details-image"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-end",
            }}
          >
            <Box
              component="img"
              sx={{
                display: "block",
                maxWidth: 500,
                height: "auto",
                overflow: "hidden",
                width: "100%",
                margin: "auto",
              }}
              src={car.imageUrl}
              alt="voiture"
              className="image-des-details"
            />
          </div>
          <div id="bouttons-reserves">
            <div className="choisir-voiture">{car.prix}€/jour</div>
            {car.disponible === "oui" && (
              <div className="choisir-voiture">
                {token ? (
                  <Link to="/reservation" style={{ color: "#fff" }}>
                    {" "}
                    <span
                      onClick={() => {
                        localStorage.setItem("voiture", JSON.stringify(car));
                      }}
                      className="choisir"
                    >
                      Choisir
                    </span>{" "}
                    <FaArrowRight
                      size={25}
                      style={{ width: "20px" }}
                      className="arrow"
                    />
                  </Link>
                ) : (
                  <Link to="/register" style={{ color: "#fff" }}>
                    {" "}
                    <span
                      onClick={() => {
                        localStorage.setItem("voiture", JSON.stringify(car));
                      }}
                      className="choisir"
                    >
                      Choisir
                    </span>{" "}
                    <FaArrowRight
                      size={25}
                      style={{ width: "20px" }}
                      className="arrow"
                    />
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className="partie-details-voitures">
            <div
              id="section-groupe-details-voiture"
              style={{ display: "flex" }}
            >
              <div id="sous-groupe">
                {" "}
                <div id="groupe-details">
                  {" "}
                  <div className="details-des-voiture">
                    <StarsIcon sx={{ color: "#e2ad2b", fontSize: 30 }} />
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>Marque :</span>{" "}
                    <span>{car.marque}</span>
                  </div>
                  <div className="details-des-voiture">
                    <DirectionsCarIcon
                      sx={{ color: "#e2ad2b", fontSize: 30 }}
                    />
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>Modele :</span>{" "}
                    <span>{car.modele}</span>
                  </div>
                  <div className="details-des-voiture">
                    <DirectionsCarIcon
                      sx={{ color: "#e2ad2b", fontSize: 30 }}
                    />
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>Type :</span>{" "}
                    <span>{car.type}</span>
                  </div>
                </div>
                <div id="groupe-details">
                  <div className="details-des-voiture">
                    <SettingsInputComponentIcon
                      sx={{ color: "#e2ad2b", fontSize: 30 }}
                    />
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>Boite :</span>{" "}
                    <span>{car.boite}</span>
                  </div>
                  <div className="details-des-voiture">
                    <PaletteIcon sx={{ color: "#e2ad2b", fontSize: 30 }} />
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>Couleur :</span>{" "}
                    <span>{car.couleur}</span>
                  </div>
                  <div className="details-des-voiture">
                    <FlashOnIcon sx={{ color: "#e2ad2b", fontSize: 30 }} />
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>Energie :</span>{" "}
                    <span>{car.energie}</span>
                  </div>
                </div>
              </div>

              <div id="sous-groupe">
                {" "}
                <div id="groupe-details">
                  <div className="details-des-voiture">
                    <EventIcon sx={{ color: "#e2ad2b", fontSize: 30 }} />
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>Année :</span>{" "}
                    <span>{car.annee}</span>
                  </div>
                  <div className="details-des-voiture">
                    <PersonIcon sx={{ color: "#e2ad2b", fontSize: 30 }} />
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>Passagers :</span>{" "}
                    <span>{car.nbrPlace}</span>
                  </div>
                  <div className="details-des-voiture">
                    <MeetingRoomIcon sx={{ color: "#e2ad2b", fontSize: 30 }} />
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>Portes : </span>{" "}
                    <span>{car.nbrPorte}</span>
                  </div>
                </div>
                <div id="groupe-details">
                  <div className="details-des-voiture">
                    <SettingsSuggestIcon
                      sx={{ color: "#e2ad2b", fontSize: 30 }}
                    />
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>Moteur : </span>{" "}
                    <span>{car.moteur}</span>
                  </div>
                  <div className="details-des-voiture">
                    <LocalGasStationIcon
                      sx={{ color: "#e2ad2b", fontSize: 30 }}
                    />
                    &nbsp;
                    <span style={{ fontWeight: "bold" }}>
                      Carburant :{" "}
                    </span>{" "}
                    <span>{car.carburant}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="infos-circle">
              <InfoIcon style={{ color: "infos-circle" }} />{" "}
              <span style={{ fontWeight: "bolder" }}>Terme et conditions</span>
            </div>
          </div>
          <Divider />
          <div className="details-location">
            <h5>Details de la location</h5>
            <div className="details-location-partie">
              <div className="details-location-droite">
                <div>
                  <ContactMailIcon style={{ color: "#e2ad2b" }} />{" "}
                  <span>Garantie du paiement</span>
                </div>
                <div>
                  <WorkHistoryIcon style={{ color: "#e2ad2b" }} />{" "}
                  <span>Protégez votre location</span>
                </div>
                <div>
                  <EmailIcon style={{ color: "#e2ad2b" }} />{" "}
                  <span>Réception par courrier</span>
                </div>
              </div>
              <div className="details-location-gauche">
                <div>
                  <GroupIcon style={{ color: "#e2ad2b" }} />{" "}
                  <span>Garantie du paiement</span>
                </div>
                <div>
                  <TranslateIcon style={{ color: "#e2ad2b" }} />{" "}
                  <span>Protégez votre location</span>
                </div>
                <div>
                  <PaymentIcon style={{ color: "#e2ad2b" }} />{" "}
                  <span>Réception par courrier</span>
                </div>
              </div>
            </div>
          </div>
          <Divider />

          <div className="reviews-partie">
            <h2>Revues récentes :</h2>
            {twoLastAvis.length !== 0 ? (
              twoLastAvis.map((avis, index) => (
                <div key={index}>
                  <div className="reviews">
                    <div className="reviews-ensemble">
                      <Avatar
                        sx={{
                          bgcolor: "orange",
                          width: "60px",
                          height: "60px",
                        }}
                      >
                        {avis.nom[0]}
                      </Avatar>
                      <div className="reviews-details">
                        <span className="titre-reviews">{avis.nom}</span>
                        <div className="reviews-starts">
                          <div style={{ display: "none" }}>
                            {
                              (numbers = tabNumbers.filter(
                                (number) => number < avis.etoiles
                              ))
                            }
                          </div>

                          {numbers.map((number, index) => (
                            <StarIcon
                              key={index}
                              style={{ color: "#e2ad2b" }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <p className="reviews-texte">{avis.commentaire}</p>
                  </div>
                  <Divider />
                </div>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  lineHeight: "4vh",
                }}
                className="rating-partie"
              >
                <span>Il n'y'a pas encore d'avis pour cette voiture.</span>
                <b>Soyez le premier a laisser votre avis sur la "{car.nom}"</b>
                <p>Votre adresse e-mail ne sera pas publiée.</p>
              </div>
            )}
          </div>
          {twoLastAvis.length !== 0 && avisCar.length >= 5 ? (
            <div className="voir-commentaires">
              <span
                onClick={() => handleOpen(true)}
                style={{ cursor: "pointer" }}
              >
                Voir tous les commentaires{" "}
                <FaArrowRight size={25} style={{ width: "20px" }} />
              </span>
            </div>
          ) : null}

          <FormAvis
            voiture_id={voiture_id}
            setAvis={setAvis}
            avisCar={avisCar}
          />
        </div>
      )}
    </section>
  );
}
