import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import GradeIcon from "@mui/icons-material/Grade";
import "./CarPromotionCard.css";
import { Link } from "react-router-dom";
export default function CarPromotionCard({ car }) {
  return (
    <React.Fragment>
      <Link to={`/details/${car.id}`}>
        <Box>
          <Card
            variant="outlined"
            sx={{
              width: "310px",
              height: "auto",
              boxShadow: "0 1px 4px 0 rgba(108, 108, 108, 0.25)",
            }}
            className="card"
          >
            <CardContent>
              <Typography mb={5} component="h2" className="car-name">
                {car.nom && car.nom.length > 10
                  ? car.nom.substring(0, 10) + "..."
                  : car.nom}
              </Typography>
              {car.disponible === "oui" ? (
                <div className="featured">
                  <span className="featured-text-disponible"> Disponible</span>
                  {/*<FavoriteBorderIcon /> */}
                </div>
              ) : (
                <div className="featured">
                  <span className="featured-text">Non disponible</span>
                  {/*<FavoriteBorderIcon /> */}
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "170px",
                }}
              >
                <img
                  src={car.imageUrl}
                  alt=""
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                    maxWidth: "300px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "3vh",
                }}
              >
                <h3 className="car-prix">{car.prix}€/jour</h3>
                <span style={{ fontWeight: "bold" }}>
                  <button className="car-rating">voir details</button>
                </span>
              </div>
              <div className="details-auto" style={{ width: "100%" }}>
                <div
                  style={{
                    borderRight: "2px solid #ccc",
                    padding: "0 6px 6px 0",
                    textAlign: "center",
                  }}
                >
                  <span>{car.type}</span>
                </div>
                <div
                  style={{
                    borderRight: "2px solid #ccc",
                    padding: "0 6px 6px 6px",
                    textAlign: "center",
                  }}
                >
                  <span>{car.boite === "automatic" ? "auto" : car.boite}</span>
                </div>

                <div
                  style={{
					borderRight: "2px solid #ccc",
                    padding: "0 6px 6px 6px",
					textAlign: "center",
                  }}
                  className="passagers"
                >
                  <span>{car.nbrPorte}</span> &nbsp;
                  <span>portes</span>
                </div>

                <div
                  style={{
                    padding: "0 0 6px 6px",
					textAlign: "center",
                  }}
                >
                  <span>{car.carburant}</span>
                </div>
              </div>
            </CardContent>
          </Card>
        </Box>
      </Link>
    </React.Fragment>
  );
}
