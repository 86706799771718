import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import FormulaireReservation from "../FormulaireReservation/FormulaireReservation";
const public_key =
  "pk_test_51Mtu60FSSK6KLWgPTw7a5rVewVsK8za8ZeOEp89DwftFjQOdOUfFdPZuLDyGcJdaBMrXbjVkaGNqHwiUgBE2k3vG00ivR8H8wi";

//Permet d'initialiser l'objet stripe
const stripeTestPromise = loadStripe(public_key);
export default function StripeContainer() {
  return (
    //Fournisseur de l'objet stripe
    <Elements stripe={stripeTestPromise}>
      <CheckoutForm />
    </Elements>
  );
}
