import React, { Fragment } from "react";
import { useParams, useLocation } from "react-router-dom";
import Bgheader from "../../Components/BgHeader";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import SectionDetailsVoiture from "../../Components/SectionDetailsVoiture/SectionDetailsVoiture";
import SectionFiltresCollection from "../../Components/SectionFiltresCollection/SectionFiltresCollection";
import "./DetailsVoiture.css";
import { getAvisCar, getOneCar } from "../../Services/Services";
export default function DetailsVoiture() {
  const Params = useParams();

  const location = useLocation();
  const id = Params.id;
  const [oneCar, setOneCar] = React.useState({});
  const [avisCar, setAvis] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [rating, setRating] = React.useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    getOneCar(setOneCar, id, setLoading);
    getAvisCar(setAvis, id);
  }, []);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  let twoLastAvis;
  if (avisCar.length > 5) {
    twoLastAvis = avisCar.slice(avisCar.length - 5, avisCar.lenght);
  } else {
    twoLastAvis = avisCar;
  }
  document.title = "Détails";

  return (
    <Fragment>
      <div>
        <Navbar />
        <Bgheader heading="DETAILS DE LA VOITURE" />
      </div>
      <div className="containerDetails">
        <div className="details-voiture">
          <SectionDetailsVoiture
            loading={loading}
            car={oneCar}
            avisCar={avisCar}
            twoLastAvis={twoLastAvis}
            setAvis={setAvis}
            voiture_id={id}
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
            handleOpen={handleOpen}
            setRating={setRating}
            rating={rating}
          />
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </Fragment>
  );
}
