import * as Yup from "yup";
export const RegisterValidationSchema = Yup.object().shape({
  nom: Yup.string().required("Le nom est obligatoire"),
  email: Yup.string()
    .email("email invalide")
    .required("L'email est obligatoire"),
  telephone: Yup.string()
    .min(9, "Au minimum 9 chiffres")
    .max(9, "au maximum 9 chiffres")
    .required("Le numéro est obligatoire"),
  pays: Yup.string().required("Veuillez entrer le pays"),
  adresse: Yup.string().required("Veuillez entrer votre adresse"),
  password: Yup.string()
    .required("Le mot de passe est obligatoire")
    .min(8, "au moins 8 caracteres"),
  confirmPassword: Yup.string().required(
    "La confirmation du mot de passe est obligatoire"
  ),
});
