import * as Yup from "yup";
export const ContactValidationSchema = Yup.object().shape({
  nom: Yup.string().required("Le nom est obligatoire"),
  prenom: Yup.string().required("Le prenom est obligatoire"),
  email: Yup.string()
    .email("email invalide")
    .required("L'email est obligatoire"),
    numero: Yup.number().required("Le numero est obligatoire"),
    sujet: Yup.string().required("Le sujet est obligatoire"),
    commentaire: Yup.string().required("Le commentaire est obligatoire"),
});
