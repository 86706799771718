import { TableCell } from "@mui/material";
import React from "react";
import { getOneCar } from "../../Services/Services";
import "./AfficheVoitureReserved.css";

export default function AfficheVoitureReserved({ voiture_id }) {
  const [oneCar, setOne] = React.useState([]);
  React.useEffect(() => {
    getOneCar(setOne, voiture_id);
  }, []);
  return (
    <>
      <TableCell component="th" scope="row">
        <img
          src={oneCar && oneCar.imageUrl}
          alt="voiture"
          className="image_historique"
        />
      </TableCell>

      <TableCell align="center">{oneCar && oneCar.marque}</TableCell>
      <TableCell align="center">{oneCar && oneCar.modele}</TableCell>
    </>
  );
}
