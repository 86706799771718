import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";
import "./PaginationCard.css";
export default function PaginationCard({
  totalCars,
  postsPerPage,
  setCurrentPage,
  currentPage,
}) {
  //trouver le nombre de pages
  let pages = [];
  const nombre = Math.ceil(totalCars / postsPerPage);
  return (
    <div className="pagination">
      <Stack sx={{ margin: "auto" }}>
        <Pagination
          count={nombre}
          variant="outlined"
          page={currentPage}
          defaultPage={1}
          shape="rounded"
          onChange={(e, p) => {
            setCurrentPage(p);
          }}
        />
      </Stack>
    </div>
  );
}
