import React from "react";
import { getAllMarques } from "../../Services/Services";
import SelectMarqueFilter from "../../UI/Inputs/SelectMarqueFilter/SelectMarqueFilter";
import SelectTypeFilter from "../../UI/Inputs/SelectTypeFilter/SelectTypeFilter";
import SelectTypeVoiture from "../SelectTypeVoiture/SelectTypeVoiture";
import "./SectionRechercheCollection.css";

export default function SectionRechercheCollection({
  allCars,
  setAllCars,
  setMarque,
  marque,
  filter,
}) {
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    getAllMarques(setOptions);
  }, []);
  return (
    <div id="search-bar">
      <span className="offre">Offres disponibles : {allCars.length}</span>
      <div className="select-voiture">
        <SelectMarqueFilter
          options={options}
          titreSelect="Filtrer par marque"
          setTypeVoiture={setMarque}
          typeVoiture={marque}
        />
      </div>
    </div>
  );
}
