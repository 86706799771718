import * as Yup from "yup";
export const EditValidation = Yup.object().shape({
  nom: Yup.string().required("Le nom est obligatoire"),
  email: Yup.string()
    .email("email invalide")
    .required("L'email est obligatoire"),
  telephone: Yup.string()
    .min(9, "Au minimum 9 chiffres")
    .max(9, "au maximum 9 chiffres")
    .required("Le numéro est obligatoire"),
  pays: Yup.string().required("Veuillez entrer le pays"),
  adresse: Yup.string().required("Veuillez entrer votre adresse"),
});
