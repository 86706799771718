import * as Yup from "yup";
export const FormAvisValidationSchema = Yup.object().shape({
  etoiles: Yup.number()
    .required("La note es obligatoire")
    .min(1, "La note es obligatoire"),
  nom: Yup.string().required("Le nom est obligatoire"),
  email: Yup.string()
    .email("email invalide")
    .required("L'email est obligatoire"),
  commentaire: Yup.string().required("Le commentaire est obligatoire"),
});
