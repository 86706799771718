import * as Yup from "yup";
export const FormulaireReservationValidationSchema = Yup.object().shape({
  lieuCharge: Yup.string().required("Veuillez saisir le lieu de ramassage"),
  dateDebut: Yup.date().required("Saisissez la date"),
  heureDebut: Yup.string().required("l'heure est obligatoire"),
  locRetour: Yup.string().required("Veuillez saisir le lieu de ramassage"),
  dateFin: Yup.date()
    .required("Saisissez la date")
    .min(Yup.ref("dateDebut"), "Vérifier la date de fin"),
  heureDFin: Yup.string().required("l'heure est obligatoire"),
});
