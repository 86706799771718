import React from "react";
import ServiceCard from "../ServiceCard/ServiceCard";
import "./SectionPourquoiNous.css";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import SecurityIcon from "@mui/icons-material/Security";
import WorkIcon from "@mui/icons-material/Work";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import SpeedIcon from "@mui/icons-material/Speed";
import SectionPub from "../SectionPub/SectionPub";

export default function SectionPourquoiNous({ tabElements }) {
  return (
    <div>
      <section className="section-pourquoi">
        <div className="titre">
          <div className="tiret-droit"></div>
          <h2 className=" titrepn">POURQUOI NOUS</h2>
          <div className="tiret-gauche"></div>
        </div>
        <div className="section-services">
          <ServiceCard
            titre="Paiement sécurisé
        Garantie"
            description=" Nous vous garantissons un paiement sécurisé. Les
            moyens de paiement acceptés sont les suivants : carte bancaire sur serveur
            sécurisé, carte bancaire par téléphone, paypal, virement bancaire
            "
            icon={<SecurityIcon style={{ color: "#e2ad2b", fontSize: 40 }} />}
          />
          <ServiceCard
            titre="CENTRE D'AIDE ET SUPPORT 24/7"
            description=" Notre centre d'aide et de support est
            accessible par téléphone, par e-mail et par chat en direct, pour répondre à
            toutes vos questions ou préoccupations."
            icon={<HeadsetMicIcon style={{ color: "#e2ad2b", fontSize: 40 }} />}
          />
          <ServiceCard
            titre="Réservation de véhicules de toute catégorie"
            description="Louer nos voitures à des tarifs
            exceptionnels, Effectuez votre réservation en toute tranquillité et simplicité, en
            bénéficiant d'un processus sécurisé et facile"
            icon={<SecurityIcon style={{ color: "#e2ad2b", fontSize: 40 }} />}
          />
          <ServiceCard
            titre="Services aux entreprises et services commerciaux"
            description=" Nos services offrent une
            flexibilité et une commodité accrues pour vos déplacements. Que vous ayez
            besoin d'une voiture pour un employé, ou des véhicules pour une flotte
            d'entreprise nous sommes là pour vous accompagner."
            icon={<WorkIcon style={{ color: "#e2ad2b", fontSize: 40 }} />}
          />
          <ServiceCard
            titre="Pas de frais caché"
            description=" louer nos voitures à des tarifs défiant toute concurrence,
            transparents sans frais cachés ni surprises de dernière minute. Profitez d’une
            expérience de location de voiture sans tracas"
            icon={<EuroSymbolIcon style={{ color: "#e2ad2b", fontSize: 40 }} />}
          />
          <ServiceCard
            titre="Kilomètres
        illimitéS"
            description=" Simplifiez votre expérience de location en optant pour une
            voiture avec kilométrage illimité. Chez Good Loc, nous vous offrons la possibilité
            de louer une voiture sans vous soucier des limitations de kilométrage."
            icon={<SpeedIcon style={{ color: "#e2ad2b", fontSize: 40 }} />}
          />
        </div>
      </section>
      <SectionPub tabElements={tabElements} />
    </div>
  );
}
