import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CarRentalIcon from "@mui/icons-material/CarRental";
import Paper from "@mui/material/Paper";
import { getOneCar } from "../../Services/Services";
import AfficheVoitureReserved from "../../Components/AfficheVoitureReserved/AfficheVoitureReserved";
import "./TableReservation.css";
import Affiche2 from "../../Components/Affiche2/Affiche2";
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function TableReservation({ allReservations }) {
  return allReservations.length !== 0 ? (
    <>
      <TableContainer component={Paper} className="table-affichage">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell
                align="center"
                sx={{ color: "#8a8886", fontSize: "13px" }}
              >
                Marque
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#8a8886", fontSize: "13px" }}
              >
                Modele
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#8a8886", fontSize: "13px" }}
              >
                Type de paiement
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#8a8886", fontSize: "13px" }}
              >
                Date de debut
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "#8a8886", fontSize: "13px" }}
              >
                Total(euros)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allReservations.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <AfficheVoitureReserved voiture_id={row.voiture_id} />
                <TableCell align="center">{row.payement}</TableCell>
                <TableCell align="center">{row.dateDebut}</TableCell>
                <TableCell align="center">
                  <span>{row.total}</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="affiche2-mobile">
        {allReservations.map((row, index) => (
          <div key={index}>
            <Affiche2
              row={row}
              voiture_id={row.voiture_id}
              type={row.payement}
            />
          </div>
        ))}
      </div>
    </>
  ) : (
    <>
      <span
        style={{
          display: "flex",
          fontWeight: "bold",
          justifyContent: "center",
          fontSize: "18px",
        }}
      >
        L'historique de réservations est vide
      </span>
      <div
        style={{
          width: "100%",

          display: "flex",
          justifyContent: "center",
          marginTop: "5vh",
        }}
      >
        <CarRentalIcon style={{ fontSize: 100, color: "#e2ad2b" }} />
      </div>
    </>
  );
}
