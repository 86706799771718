import React from "react";
import Navbar from "../Components/Navbar";
import BgImage from "../Components/BgImage";
import Footer from "../Components/Footer";
import ReservationCard from "../Components/ReservationCard/ReservationCard";
import SectionPromotion from "../Components/SectionPromotion/SectionPromotion";
import SectionPourquoiNous from "../Components/SectionPourquoiNous/SectionPourquoiNous";
import SectionAgences from "../Components/SectionAgences/SectionAgences";
import SectionAvis from "../Components/SectionAvis/SectionAvis";
import { Outlet, useLocation } from "react-router-dom";
import { AllTypesCars, getAllCars, getAllVilles } from "../Services/Services";
import { logDOM } from "@testing-library/react";
export const Accueil = () => {
  const [carsPerTypes, setCarsPerTypes] = React.useState([]);
  document.title = "Accueil";
  const [loading, setLoading] = React.useState(true);
  const [allCars, setAllCars] = React.useState([]);
  const [uniqueVilles, setUniqueVilles] = React.useState([]);
  const [uniqueTypes, setUniqueTypes] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsParPage, setPostsParPage] = React.useState(6);
  const indiceFin = currentPage * postsParPage;
  const indiceDebut = indiceFin - postsParPage;
  const carsPerPage = carsPerTypes.slice(indiceDebut, indiceFin);
  let fiveLastElements = allCars.slice(allCars.length - 5, allCars.length);
  let fiveLastTypes = uniqueTypes.slice(
    uniqueTypes.length - 9,
    uniqueTypes.length
  );
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  React.useEffect(() => {
    AllTypesCars(setUniqueTypes);
    getAllVilles(setUniqueVilles);
    getAllCars(setAllCars, setLoading);
    getAllCars(setCarsPerTypes, setLoading);
  }, []);

  return (
    <div>
      <Navbar />
      <BgImage />
      <ReservationCard uniqueCars={uniqueVilles} />
      <SectionPromotion
        carsPerPage={carsPerPage}
        allCars={allCars}
        carsPerTypes={carsPerTypes}
        uniqueTypes={fiveLastTypes}
        setCarsPerTypes={setCarsPerTypes}
        postsParPage={postsParPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        setLoading={setLoading}
      />
      <SectionPourquoiNous tabElements={fiveLastElements} />
      <SectionAgences />
      <SectionAvis />
      <Footer />
    </div>
  );
};
