import React, { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BgHeader from "../BgHeader";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { FaFileExport, FaFileInvoiceDollar, FaUser } from "react-icons/fa";
import "./compte.css";
import { AllReservatio, CompteClient, Logout } from "../../Services/Services";
import { useAuthContext } from "../../Contextes/AuthProvider";

const Compte = () => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const { user, cars, setUser_id, userId } = useAuthContext();
  React.useEffect(() => {
    if (userId !== localStorage.getItem("user_id")) {
      setUser_id(
        localStorage.getItem("user_id") && localStorage.getItem("user_id")
      );
    }
  }, []);
  document.title = "Compte";

  const navigate = useNavigate();
  const [token, setToken] = React.useState(
    localStorage.getItem("token") ? localStorage.getItem("token") : null
  );

  return (
    <Fragment>
      <div>
        <Navbar heading="MON COMPTE" />
        <BgHeader heading="Compte" />
      </div>

      <div className="containerCompte">
        <div className="section-My-Comte">
          <div className="details-Compte">
            <h2> Détails du compte :</h2>
            <div className="profile-user">
              <FaUser className="icon-profile-compte" />
              <div>
                <span>{user.nom}</span>
              </div>
            </div>

            <div className="profile-user">
              <FaFileExport className="icon-profile-compte" />
              <div>
                <Link to={"/profil-reservation"}>
                  <span>Profil des réservations</span>
                </Link>
              </div>
            </div>

            <div className="profile-user">
              <FaFileInvoiceDollar className="icon-profile-compte" />
              <div>
                <Link to={"/historique-Reservation"}>
                  <span>Historique des réservations</span>
                </Link>
              </div>
            </div>
            <div className="profile-user d">
              <Link to={"#"}>
                <span
                  onClick={async () => {
                    const result = await Logout(setToken);
                    if (result.status === 200) {
                      navigate("/");
                    }
                  }}
                >
                  Deconnexion
                </span>
              </Link>
              <span className="Path"></span>
            </div>
          </div>

          <div className="information-Compte">
            <h2> compte :</h2>
            <div className="info-user">
              <span className="n-compte">Name :</span>
              <div>
                <h4>{user.nom}</h4>
              </div>
            </div>

            <div className="info-user">
              <span className="n-compte">Email :</span>
              <div>
                <h4>{user.email}</h4>
              </div>
            </div>

            <div className="info-user">
              <span className="n-compte">Phone Number :</span>
              <div>
                <h4>{user.telephone}</h4>
              </div>
            </div>

            <div className="info-user">
              <span className="n-compte">Pays :</span>
              <div>
                <h4>{user.pays}</h4>
              </div>
            </div>

            <div className="info-user">
              <span className="n-compte">Adresse :</span>
              <div>
                <h4>{user.adresse}</h4>
              </div>
            </div>
            <div className="info-user m">
              <Link to={"/edit-profil"}>
                <span>Modifier les informations du compte</span>
              </Link>
              <span className="Path-info"></span>
            </div>
            <hr className="uderline" />

            <div className="profile-Reservation">
              <h2>Profil des réservations :</h2>
              <div className="info-reservation">
                <span className="n-reservation">ID de profil :</span>
                <div>
                  <h4>{user.nom}</h4>
                </div>
              </div>

              <div className="info-reservation">
                <span className="n-reservation">Mode de paiement :</span>
                <div>
                  <h4>{user.mode_payement ? user.mode_payement : "xxxxxx"}</h4>
                </div>
              </div>

              <div className="info-reservation">
                <span className="n-reservation">N° fixe:</span>
                <div>
                  <h4>{user.fixe ? user.fixe : "xx xxx xxxx"}</h4>
                </div>
              </div>

              <div className="info-reservation r">
                <Link to={"/profil-reservation"}>
                  <span>Profil des réservations</span>
                </Link>
                <span className="Path-r"></span>
              </div>
              <hr className="uderline" />
            </div>

            <div className="profile-Reservation">
              <h2>Historique des réservations :</h2>
              <div className="info-reservation">
                <span className="n-reservation">Prochaines Réservations :</span>
                <div>
                  <h4>Aucune réservation pour l'instant</h4>
                </div>
              </div>

              <div className="info-reservation">
                <span className="n-reservation">Locations précédentes :</span>
                <div>
                  <h4>{cars.length}</h4>
                </div>
              </div>

              <div className="info-reservation r">
                <Link to={"/nos-vehicules"}>
                  <span>Réserver une voiture</span>
                </Link>
                <span className="Path-r"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Compte;
