import React, { useState } from "react";
import Bgheader from "../Components/BgHeader";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import PaginationCard from "../Components/PaginationCars/PaginationCard";
import SectionCollectionPagination from "../Components/SectionCollectionPagination/SectionCollectionPagination";
import SectionFiltresCollection from "../Components/SectionFiltresCollection/SectionFiltresCollection";
import SectionRechercheCollection from "../Components/SectionRechercheCollection/SectionRechercheCollection";
import "./Nos_vehicules.css";
import { getAllCars } from "../Services/Services";
import { renitialiseFiltres } from "../Services/fonctions";
import { useLocation } from "react-router-dom";
export default function Nos_vehicules() {
  document.title = "Véhicules";
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const [checkedPlaces, setCheckedPlaces] = React.useState([]);
  const [isCheckbox2Checked, setIsCheckbox2Checked] = useState(false);
  const [isCheckbox3Checked, setIsCheckbox3Checked] = useState(false);
  const [isCheckbox4Checked, setIsCheckbox4Checked] = useState(false);
  const [isCheckbox5Checked, setIsCheckbox5Checked] = useState(false);
  const [allCars, setAllCars] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [type, setType] = React.useState([]);
  const [typeModele, setTypeModele] = React.useState("");
  const [typeboite, setTypeBoite] = React.useState("");
  const [marque, setMarque] = React.useState("");
  const [couleur, setCouleur] = React.useState("");
  const [typeCarburant, setTypeCarburant] = React.useState("");
  const [annee, setAnnee] = React.useState("");
  const [informationsReservation, setInformationsReservation] = React.useState({
    prix: 800,
  });
  const [filteredCars, setFilteredCars] = React.useState([]);
  const [postsParPage, setPostsParPage] = React.useState(6);
  const [currentPage, setCurrentPage] = React.useState(1);
  const indiceFin = currentPage * postsParPage;
  const indiceDebut = indiceFin - postsParPage;
  let cars = filteredCars.slice(indiceDebut, indiceFin);
  const uniqueCarburant = allCars
    .map((item) => item.carburant)
    .filter((value, index, self) => self.indexOf(value) === index);
  const uniqueBoite = allCars
    .map((item) => item.boite)
    .filter((value, index, self) => self.indexOf(value) === index);
  const unique = allCars
    .map((item) => item.modele)
    .filter((value, index, self) => self.indexOf(value) === index);
  const uniqueAnnees = allCars
    .map((item) => item.annee)
    .filter((value, index, self) => self.indexOf(value) === index);
  const uniqueCouleurs = allCars
    .map((item) => item.couleur)
    .filter((value, index, self) => self.indexOf(value) === index);
  let Filter;
  if (
    typeModele ||
    marque ||
    typeboite ||
    typeCarburant ||
    informationsReservation.prix
  ) {
    Filter = allCars.filter(
      (item) =>
        item.type.includes(typeModele) &&
        item.marque.includes(marque) &&
        item.boite.includes(typeboite) &&
        item.annee.includes(annee) &&
        item.couleur.includes(couleur) &&
        item.carburant.includes(typeCarburant) &&
        parseInt(item.prix) <= informationsReservation.prix &&
        (checkedPlaces.length === 0 ||
          checkedPlaces.includes(item.nbrPorte.toString()))
    );
  }

  React.useEffect(() => {
    getAllCars(setAllCars, setLoading);
    getAllCars(setFilteredCars, setLoading);
  }, []);

  const handleCheckedValues2 = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    setIsCheckbox2Checked(e.target.checked);

    if (checked) {
      setCheckedPlaces([...checkedPlaces, value]);
    } else {
      setCheckedPlaces(checkedPlaces.filter((val) => val !== value));
    }
  };
  const handleCheckedValues3 = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    setIsCheckbox3Checked(e.target.checked);

    if (checked) {
      setCheckedPlaces([...checkedPlaces, value]);
    } else {
      setCheckedPlaces(checkedPlaces.filter((val) => val !== value));
    }
  };
  const handleCheckedValues4 = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    setIsCheckbox4Checked(e.target.checked);

    if (checked) {
      setCheckedPlaces([...checkedPlaces, value]);
    } else {
      setCheckedPlaces(checkedPlaces.filter((val) => val !== value));
    }
  };
  const handleCheckedValues5 = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    setIsCheckbox5Checked(e.target.checked);

    if (checked) {
      setCheckedPlaces([...checkedPlaces, value]);
    } else {
      setCheckedPlaces(checkedPlaces.filter((val) => val !== value));
    }
  };
  return (
    <section>
      <Navbar />
      <Bgheader heading="NOTRE COLLECTION" />
      <div
        style={{
          padding: "0 20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {typeCarburant ||
        typeboite ||
        typeModele ||
        couleur ||
        marque ||
        annee ||
        checkedPlaces.length !== 0 ? (
          <button
            className="renitialise"
            onClick={() => {
              renitialiseFiltres(
                setTypeBoite,
                setMarque,
                setTypeModele,
                setCouleur,
                setTypeCarburant,
                setInformationsReservation,
                setAnnee,
                setCheckedPlaces,
                setIsCheckbox2Checked,
                setIsCheckbox3Checked,
                setIsCheckbox4Checked,
                setIsCheckbox5Checked,
                setFilteredCars,
                allCars
              );
            }}
            style={{
              marginBottom: "2vh",
              border: "none",
              color: "#fff",
              backgroundColor: "#e2ad2b",
              padding: "1vh 10px",
            }}
          >
            Renitialiser filtres
          </button>
        ) : (
          <span className="spanvide"></span>
        )}
      </div>

      <div id="section-collection">
        <div id="filtres">
          {" "}
          <SectionFiltresCollection
            isCheckbox2Checked={isCheckbox2Checked}
            isCheckbox3Checked={isCheckbox3Checked}
            isCheckbox4Checked={isCheckbox4Checked}
            isCheckbox5Checked={isCheckbox5Checked}
            handleCheckedValues2={handleCheckedValues2}
            handleCheckedValues3={handleCheckedValues3}
            handleCheckedValues4={handleCheckedValues4}
            handleCheckedValues5={handleCheckedValues5}
            checkedPlaces={checkedPlaces}
            setCheckedPlaces={setCheckedPlaces}
            setFilteredCars={setFilteredCars}
            Filter={Filter}
            couleur={couleur}
            setCouleur={setCouleur}
            uniqueCouleurs={uniqueCouleurs}
            uniqueCarburant={uniqueCarburant}
            uniqueAnnees={uniqueAnnees}
            uniqueBoite={uniqueBoite}
            unique={unique}
            setCars={setAllCars}
            allCars={allCars}
            informationsReservation={informationsReservation}
            type={type}
            typeCarburant={typeCarburant}
            typeboite={typeboite}
            typeModele={typeModele}
            annee={annee}
            setAnnee={setAnnee}
            setType={setType}
            setTypeCarburant={setTypeCarburant}
            setTypeBoite={setTypeBoite}
            setTypeModele={setTypeModele}
            setInformationsReservation={setInformationsReservation}
          />{" "}
        </div>
        <div id="collections">
          <div id="recherche-collection">
            <SectionRechercheCollection
              allCars={filteredCars}
              setAllCars={setAllCars}
              setMarque={setMarque}
              marque={marque}
            />
          </div>
          <div id="collection-pagination">
            <SectionCollectionPagination cars={cars} loading={loading} />
            {filteredCars.length !== 0 && (
              <PaginationCard
                totalCars={filteredCars.length}
                postsPerPage={postsParPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}
