import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

export default function PrivateRouteFirewall({ children }) {
  //simulation en attendant l'api
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }
  return children;
}
