import React from "react";
import "./index.css";
import { Accueil } from "./Pages/Accueil";
import Nos_vehicules from "./Pages/Nos_vehicules";
import Faqs from "./Pages/faq/Faqs";
import Contact from "./Pages/Contact";
import { Route, Routes } from "react-router-dom";
import Login from "./Components/Connexion/Login";
import Compte from "./Components/MonCompte/Compte";
import ReservationProfile from "./Components/MonCompte/ReservationProfile";
import EditProfil from "./Components/MonCompte/EditProfil";
import Register from "./Components/Connexion/Register";
import PasswordForget from "./Components/Connexion/PasswordForget";
import Reservation from "./Pages/Reservation";
import InfoBeeLoc from "./Pages/faq/faq-content/InfoBeeLoc";
import AideMoi from "./Components/PageAide/AideMoi";
import DetailsVoiture from "./Pages/DetailsVoiture/DetailsVoiture";
import ListeVoitures from "./Pages/ListeVoitures/ListeVoitures";
import PrivateRouteFirewall from "./Routes/PrivateRouteFirewall";
import HistoriqueReservation from "./Pages/HistoriqueReservation/HistoriqueReservation";
import AuthProvider from "./Contextes/AuthProvider";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const public_key = process.env.REACT_APP_Public;

//Permet d'initialiser l'objet stripe
const stripeTestPromise = loadStripe(public_key);
function App() {
  return (
    <AuthProvider>
      <Elements stripe={stripeTestPromise}>
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/nos-vehicules" element={<Nos_vehicules />} />
          <Route path="/details/:id" element={<DetailsVoiture />} />{" "}
          <Route
            path="/reservation"
            element={
              <PrivateRouteFirewall>
                <Reservation />{" "}
              </PrivateRouteFirewall>
            }
          />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/aide" element={<AideMoi />} />
          <Route path="/forget-password" element={<PasswordForget />} />
          <Route path="/infos-good-loc" element={<InfoBeeLoc />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/compte"
            element={
              <PrivateRouteFirewall>
                <Compte />{" "}
              </PrivateRouteFirewall>
            }
          />
          <Route
            path="/liste-voitures/:typevoiture"
            element={<ListeVoitures />}
          />
          <Route path="/reservation" element={<Reservation />} />
          <Route
            path="/historique-Reservation"
            element={
              <PrivateRouteFirewall>
                <HistoriqueReservation />{" "}
              </PrivateRouteFirewall>
            }
          />
          <Route
            path="/profil-reservation"
            element={
              <PrivateRouteFirewall>
                <ReservationProfile />{" "}
              </PrivateRouteFirewall>
            }
          />
          <Route path="/edit-profil" element={<EditProfil />} />
        </Routes>
      </Elements>
    </AuthProvider>
  );
}

export default App;
