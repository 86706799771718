import React, { Fragment } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import BgHeader from "../BgHeader";
import Navbar from "../Navbar";
import Footer from "../Footer";
import "./register.css";
import { Formik } from "formik";
import { login, register } from "../../Services/Services";
import { RegisterValidationSchema } from "./RegisterValidationSchema";
import { CircularProgress } from "@mui/material";
import MyCircularProgress from "../../UI/CircularProgress/CircularProgress";
import { notify } from "../../Services/fonctions";
function Register() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  let resultRegister;
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  document.title = "Inscription";
  return (
    <Fragment>
      <div>
        <Navbar />
        <BgHeader heading="OUVERTURE DE SESSION" />
      </div>
      {loading ? (
        <div className="CircularArea">
          {" "}
          <MyCircularProgress />
        </div>
      ) : (
        <div className="containerRegister">
          <div className="pageRegister">
            <div className="formRegister">
              <div className="infoRegister">
                <div className="pointerRegister"></div>
                <h2>Inscription</h2>
                <div className="pointerRegister"></div>
              </div>
              <div className="formRegisterGroup">
                <Formik
                  validationSchema={RegisterValidationSchema}
                  initialValues={{
                    nom: "",
                    email: "",
                    telephone: "",
                    password: "",
                    confirmPassword: "",
                    pays: "",
                    adresse: "",
                    created_at: null,
                    updated_at: null,
                  }}
                  onSubmit={async (values) => {
                    setLoading(true);
                    if (values.password === values.confirmPassword) {
                      resultRegister = await register(
                        {
                          nom: values.nom,
                          email: values.email,
                          telephone: values.telephone,
                          password: values.password,
                          pays: values.pays,
                          adresse: values.adresse,
                        },
                        setLoading
                      );
                    } else {
                      notify("les mots de passes ne correspondent pas");
                      setLoading(false);
                    }

                    if (
                      resultRegister.status === 201 ||
                      resultRegister.status === 200
                    ) {
                      const result = await login({
                        email: values.email,
                        password: values.password,
                      });
                      if (result.status === 200) {
                        notify("Vous etes connecté");
                        navigate("/");
                      }
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="formInputGroup">
                        <div className="inputFieldRegister">
                          <input
                            type="text"
                            placeholder="Nom et Prenom"
                            name="nom"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nom}
                          />
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              marginBottom: "2vh",
                            }}
                          >
                            {errors.nom && touched.nom && errors.nom}
                          </p>
                        </div>

                        <div className="inputFieldRegister">
                          <input
                            type="email"
                            placeholder="Entré votre email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              marginBottom: "2vh",
                            }}
                          >
                            {errors.email && touched.email && errors.email}
                          </p>
                        </div>
                      </div>

                      <div className="formInputGroup">
                        <div className="inputFieldRegister">
                          <input
                            type="text"
                            placeholder="Entré votre numéro"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="telephone"
                            value={values.telephone}
                          />
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              marginBottom: "2vh",
                            }}
                          >
                            {errors.telephone &&
                              touched.telephone &&
                              errors.telephone}
                          </p>
                        </div>

                        <div className="inputFieldRegister">
                          <input
                            type="text"
                            placeholder="Ville"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="pays"
                            value={values.pays}
                          />
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              marginBottom: "2vh",
                            }}
                          >
                            {errors.pays && touched.pays && errors.pays}
                          </p>
                        </div>
                      </div>
                      <div className="inputFieldRegister">
                        <input
                          type="text"
                          placeholder="Adresse"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="adresse"
                          value={values.adresse}
                        />
                        <p
                          style={{
                            color: "red",
                            fontSize: 13,
                            marginBottom: "2vh",
                          }}
                        >
                          {errors.adresse && touched.adresse && errors.adresse}
                        </p>
                      </div>
                      <div className="formInputGroup">
                        <div className="inputFieldRegister">
                          <input
                            type="password"
                            placeholder="Entré mot de passe"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              marginBottom: "2vh",
                            }}
                          >
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </p>
                        </div>

                        <div className="inputFieldRegister">
                          <input
                            type="password"
                            placeholder="Confirme mot de passe"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="confirmPassword"
                            value={values.confirmPassword}
                          />
                        </div>
                      </div>

                      <span className="YourPersonal">
                        Vos données personnelles seront utilisées dans le cadre
                        de la cartographie des véhicules que vous avez ajoutés
                        sur le site web.
                      </span>

                      <div className="inputFieldRegister button">
                        <input type="submit" value="S'inscrire" />
                      </div>
                      <div className="loginUpForm">
                        <span>Vous avez déjà un compte? </span>
                        <Link to={"/login"} className="btnLogin">
                          Cliquez-ici
                        </Link>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <Footer />
      </div>
    </Fragment>
  );
}

export default Register;
