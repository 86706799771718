import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectTypeVoiture({
  options,
  titreSelect,
  typeVoiture,
  setTypeVoiture,
}) {
  const handleChange = (event) => {
    setTypeVoiture(event.target.value);
  };

  return (
    <Box
      sx={{
        minWidth: "50%",
        color: "#fff",
        backgroundColor: "#fff",
        marginTop: "2vh",
      }}
    >
      <FormControl fullWidth>
        {titreSelect === "Filtrer par marque" && (
          <InputLabel id="demo-simple-select-label">{titreSelect}</InputLabel>
        )}
        {titreSelect === "Type de boite de vitesse" && (
          <InputLabel id="demo-simple-select-label">{titreSelect}</InputLabel>
        )}
        {titreSelect === "Type de carburant" && (
          <InputLabel id="demo-simple-select-label">{titreSelect}</InputLabel>
        )}
        {titreSelect === "Options d'espaces bagages" && (
          <InputLabel id="demo-simple-select-label">{titreSelect}</InputLabel>
        )}
        {titreSelect === "Type de voiture" && (
          <InputLabel id="demo-simple-select-label">{titreSelect}</InputLabel>
        )}
        <Select value={typeVoiture} label={titreSelect} onChange={handleChange}>
          {options.map((option, index) => (
            <MenuItem
              value={option.nomType ? option.nomType : option.nom}
              key={index}
            >
              {option.nomType ? option.nomType : option.nom}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
