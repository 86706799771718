import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import BgHeader from "../BgHeader";
import Navbar from "../Navbar";
import Footer from "../Footer";

import Aide from "./Aide";

function AideMoi() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Fragment>
      <div>
        <Navbar />
        <BgHeader heading="Aide Moi" />
      </div>
      <div>
        <Aide />
      </div>
      <div>
        <Footer />
      </div>
    </Fragment>
  );
}

export default AideMoi;
