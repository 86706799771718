import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectTypeFilter({
  options,
  titreSelect,
  typeVoiture,
  setTypeVoiture,
}) {
  const handleChange = (event) => {
    console.log(event.target.value);
    setTypeVoiture(event.target.value);
  };

  return (
    <Box
      sx={{
        minWidth: "50%",
        color: "#fff",
        backgroundColor: "#fff",
        marginTop: "2vh",
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{titreSelect}</InputLabel>

        <Select value={typeVoiture} label={titreSelect} onChange={handleChange}>
          {options.map((option, index) => (
            <MenuItem value={option} key={index}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
