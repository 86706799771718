import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import "./MenuUserConnected.css";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { Logout } from "../../Services/Services";
import { notify } from "../../Services/fonctions";

export default function MenuUserConnected() {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const navigate = useNavigate();
  React.useEffect(() => {
    localStorage.getItem("token") && setToken(localStorage.getItem("token"));
  }, [localStorage.getItem("token")]);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1, marginTop: "1vh" }}>
      {token ? (
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{ marginTop: "6vh" }}
            keepMounted
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <Link to="/compte" style={{ color: "#000" }}>
                {" "}
                Compte
              </Link>
            </MenuItem>
            <MenuItem
              onClick={async () => {
                handleClose();
                setAuth(false);
                const result = await Logout(setToken);
                if (result.status === 200) {
                  notify("Vous etes déconnecté");
                  navigate("/");
                }
              }}
            >
              Déconnexion
            </MenuItem>
          </Menu>
        </div>
      ) : (
        <div
          style={{
            padding: "1vh 20px",
            border: "1px solid #fff",
            cursor: "pointer",
          }}
          className="connexion"
        >
          <Link to="/login" style={{ color: "#fff" }}>
            Connexion
          </Link>
        </div>
      )}
    </Box>
  );
}
