import React from "react"
import "./rightBar.css"
import { FaSearch, FaHeadset, FaPhoneVolume } from "react-icons/fa"
import { Link } from "react-router-dom"
function RightBar() {
  return (
    <div className="container_f">
      <div className="search">
        {" "}
        <FaSearch className="Fa_search" />
        <input className="inp-search" type="text" placeholder="Search" />
      </div>
      <br />
      <div className="category">
        <h3>Catégories de questions :</h3>{" "}
        <Link className="faq_Link" to="/faqs">
          Informations sur BeeLoc
        </Link>
        <br />
        <Link className="faq_Link" to="">
          Informations sur la facturation
        </Link>
        <br />
        <Link className="faq_Link" to="">
          Réclamations et dommages à la voiture
        </Link>
        <br />
        <Link className="faq_Link" to="">
          Méthodes de paiement et dépôts
        </Link>
        <br />
        <Link className="faq_Link" to="">
          Cartes et programmes de bonus
        </Link>
        <br />
        <Link className="faq_Link" to="">
          Protection de la location de voitures
        </Link>
        <br />
        <Link className="faq_Link" to="">
          Mentions légales et confidentialité
        </Link>
      </div>
      <div className="centre">
        <h3>Centre d'assistance :</h3>
        <div className="items">
          <div className="item">
            {" "}
            <FaPhoneVolume />
            <span className="item">06 06 06 06 06</span>
          </div>
          <div className="item">
            {" "}
            <FaPhoneVolume />
            <span className="item"> 06 06 06 06 06</span>
          </div>
          <div className="item">
            {" "}
            <FaHeadset />
            <span className="item">help@gmail.com</span>
          </div>
          <div className="item">
            {" "}
            <FaHeadset />
            <span className="item">help@gmail.com</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RightBar
