import React, { useRef } from "react";
import "./TabsAccueil.css";
import { NavLink } from "react-router-dom";
import { getAllCars, getAllCarsPerTypes } from "../../Services/Services";

export default function TabsAccueil({
  carsPerTypes,
  uniqueTypes,
  setCarsPerTypes,
  setCurrentPage,
  allCars,
}) {
  const [activetabTab, setactivetabTab] = React.useState("tout");

  return (
    <div className="tabs">
      <ul
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          flexWrap: "wrap",
        }}
      >
        <li
          style={{ cursor: "pointer" }}
          className={activetabTab === "tout" ? "activetab" : "elementtabs"}
          onClick={() => {
            setactivetabTab("tout");
            setCurrentPage(1);
            getAllCars(setCarsPerTypes);
          }}
        >
          Tout
        </li>
        {uniqueTypes.map((uniqueType, index) => (
          <li
            key={index}
            style={{ cursor: "pointer" }}
            className={
              activetabTab === uniqueType.nomType ? "activetab" : "elementtabs"
            }
            onClick={() => {
              setactivetabTab(uniqueType.nomType);
              setCurrentPage(1);
              getAllCarsPerTypes(uniqueType.nomType, setCarsPerTypes);
            }}
          >
            {uniqueType.nomType}
          </li>
        ))}
      </ul>
    </div>
  );
}
