import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Bgheader from "../Components/BgHeader";
import "./Contact.css";
import { Formik } from "formik";
import { login } from "../Services/Services";
import emailjs from "@emailjs/browser";
import { useLocation, useNavigate } from "react-router-dom";
import { ContactValidationSchema } from "./ContactValidationSchema";
import { notify } from "../Services/fonctions";
const Contact = () => {
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  document.title = "Contact";
  return (
    <div>
      <Navbar />
      <Bgheader heading="CONTACTEZ-NOUS" />
      <div className="contactFormulaire">
        <div className="Title-Titre">
          <h1 className="title-contact">
            {" "}
            Vous n'avez pas trouvé la réponse ? Posez-nous une question
          </h1>
        </div>

        <div className="GroupContact">
          <Formik
            validationSchema={ContactValidationSchema}
            initialValues={{
              nom: "",
              prenom: "",
              email: "",
              numero: "",
              sujet: "",
              commentaire: "",
            }}
            onSubmit={(values) => {
              emailjs
                .sendForm(
                  process.env.REACT_APP_SERVICE_ID,
                  process.env.REACT_APP_TEMPLATE_ID,
                  "#form-contact",
                  process.env.REACT_APP_PUBLIC_KEY
                )
                .then(
                  (result) => {
                    // show the user a success message
                    notify("Voter message a ete envoyé");
                    navigate("/");
                  },
                  (error) => {
                    // show the user an error
                    notify(
                      "Votre message n'as pas pu etre envoyé! veuillez ressayer plus tard"
                    );
                  }
                );
            }}
          >
            {({
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit} id="form-contact">
                <div className="formContactGroup">
                  <div className="inputFieldContact">
                    <input
                      type="text"
                      placeholder="Entrez votre nom"
                      name="nom"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: "9px",
                      }}
                    >
                      {errors.nom && touched.nom && errors.nom}
                    </p>
                  </div>

                  <div className="inputFieldContact">
                    <input
                      type="text"
                      placeholder="Entrez votre Prénom"
                      name="prenom"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: 10,
                        marginBottom: "2vh",
                      }}
                    >
                      {errors.prenom && touched.prenom && errors.prenom}
                    </p>
                  </div>
                </div>

                <div className="formContactGroup">
                  <div className="inputFieldContact">
                    <input
                      type="email"
                      placeholder=" Entrez votre email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: 10,
                        marginBottom: "2vh",
                      }}
                    >
                      {errors.email && touched.email && errors.email}
                    </p>
                  </div>

                  <div className="inputFieldContact">
                    <input
                      type="text"
                      placeholder=" Entrez votre numéro"
                      name="numero"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <p
                      style={{
                        color: "red",
                        fontSize: 10,
                        marginBottom: "2vh",
                      }}
                    >
                      {errors.numero && touched.numero && errors.numero}
                    </p>
                  </div>
                </div>

                <div className="formContactSubjet">
                  <div className="inputFieldContact">
                    <input
                      type="text"
                      placeholder="Subject"
                      name="sujet"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <p
                    style={{
                      color: "red",
                      fontSize: 10,
                      marginBottom: "2vh",
                    }}
                  >
                    {errors.sujet && touched.sujet && errors.sujet}
                  </p>
                </div>

                <div className="formContactMessage">
                  <div className="inputFieldContact">
                    <textarea
                      id="message"
                      rows="5"
                      cols="33"
                      placeholder="Ecrivez..."
                      name="commentaire"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                    <p
                      style={{
                        color: "red",
                        fontSize: 10,
                        marginBottom: "2vh",
                      }}
                    >
                      {errors.commentaire &&
                        touched.commentaire &&
                        errors.commentaire}
                    </p>
                  </div>
                </div>

                <div className="submit-contact">
                  <input type="submit" value="Envoyer" id="form_button" />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
