import "./NavbarStyle.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logos from "../assets/logo-white.svg";
import { FaBars, FaSearch, FaTimes, FaUser } from "react-icons/fa";
import MenuUserConnected from "./MenuUserConnected/MenuUserConnected";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handelClick = () => setClick(!click);

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  window.addEventListener("scroll", changeColor);
  return (
    <>
      <div className="">
        <div className={color ? "header header-bg" : "header"}>
          <Link to={"/"}>
            <img src={logos} className="logo" width="110"></img>
          </Link>
          <ul className={click ? "navbar-menu active" : "navbar-menu"}>
            <li>
              {" "}
              <Link to={"/"}>Accueil</Link>{" "}
            </li>

            <li>
              <Link to={"/nos-vehicules"}>Nos vehicules</Link>
            </li>
            <li>
              <Link to={"/Faqs"}>Faqs</Link>
            </li>
            <li>
              <Link to={"/Contact"}>Contactez-Nous</Link>
            </li>
            {!localStorage.getItem("token") && (
              <li className="seConnecter">
                {" "}
                <Link to={"/login"}>Se Connecter</Link>{" "}
              </li>
            )}
          </ul>
          <div className="hamburger" onClick={handelClick}>
            {click ? (
              <FaTimes size={30} style={{ color: "white" }} />
            ) : (
              <FaBars size={30} style={{ color: "white" }} />
            )}
          </div>
          <div className="navbar-icon">
            <MenuUserConnected />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
