import React from "react";
import Bgheader from "../../Components/BgHeader";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import PaginationCard from "../../Components/PaginationCars/PaginationCard";
import SectionCollectionPagination from "../../Components/SectionCollectionPagination/SectionCollectionPagination";
import SectionFiltresCollection from "../../Components/SectionFiltresCollection/SectionFiltresCollection";
import SectionRechercheCollection from "../../Components/SectionRechercheCollection/SectionRechercheCollection";
import "./ListeVoiture.css";
import {
  getAllCars,
  getAllCarsPerTypes,
  getAllCarsPerVilles,
} from "../../Services/Services";

import { useLocation, useParams } from "react-router-dom";
export default function ListeVoitures() {
  const [allCars, setAllCars] = React.useState([]);
  const [marque, setMarque] = React.useState("");
  const [postsParPage, setPostsParPage] = React.useState(8);
  const [currentPage, setCurrentPage] = React.useState(1);
  const indiceFin = currentPage * postsParPage;
  const indiceDebut = indiceFin - postsParPage;
  const cars = allCars.slice(indiceDebut, indiceFin);
  const Params = useParams();
  const typeCars = Params.typevoiture;
  let Filter;
  document.title = "Voitures";
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  React.useEffect(() => {
    getAllCarsPerVilles(typeCars, setAllCars, setLoading);
    Filter = allCars;
  }, []);
  const [loading, setLoading] = React.useState(true);
  Filter = allCars.filter((item) => item.marque.includes(marque));
  return (
    <section>
      <Navbar />
      <Bgheader heading={`NOTRE COLLECTION`} />
      <div id="section-collection2">
        <div id="collections">
          <div id="recherche-collection">
            <SectionRechercheCollection
              allCars={Filter}
              setMarque={setMarque}
              marque={marque}
            />
          </div>
          <div id="collection-pagination">
            <SectionCollectionPagination loading={loading} cars={Filter} />
            {Filter.length ? (
              <PaginationCard
                totalCars={allCars.length}
                postsPerPage={postsParPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            ) : null}
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}
