import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";

const marks = [
  {
    value: 400,
    label: "400€",
  },
  {
    value: 800,
    label: "800€",
  },
  {
    value: 1200,
    label: "1200€",
  },
  {
    value: 1600,
    label: "1600€",
  },
];

function valuetext(value) {
  return `${value}°C`;
}

export default function SliderRange({
  informationsReservation,
  setInformationsReservation,
  color,
}) {
  const useStyles = makeStyles((theme) => ({
    margin: {
      height: theme.spacing(3),
    },
    mark: {
      color: color,
    },
  }));
  const classes = useStyles();
  return (
    <Box sx={{ width: "100%", maxWidth: 300, color: "#fff !important" }}>
      <Slider
        aria-label="Always visible"
        defaultValue={informationsReservation && informationsReservation.prix}
        value={informationsReservation && informationsReservation.prix}
        getAriaValueText={valuetext}
        step={200}
        marks={marks}
        valueLabelDisplay="on"
        style={{ color: "#e2ad2b" }}
        classes={{ markLabel: classes.mark }}
        min={400}
        max={1600}
        onChange={(e, value) =>
          setInformationsReservation({
            ...informationsReservation,
            prix: value,
          })
        }
      />
    </Box>
  );
}
