import React, { Fragment, useRef } from "react";
import {
  Link,
  Navigate,
  useHref,
  useLocation,
  useNavigate,
} from "react-router-dom";
import BgHeader from "../BgHeader";
import Navbar from "../Navbar";
import Footer from "../Footer";
import carLogin from "../../assets/carLogin.png";
import { FaEyeSlash, FaLock, FaUser } from "react-icons/fa";
import "./login.css";
import { Formik } from "formik";
import { login } from "../../Services/Services";
import { Token } from "@mui/icons-material";
import { apiInstance } from "../../Services/AxiosConfig";
import MyCircularProgress from "../../UI/CircularProgress/CircularProgress";
import { notify } from "../../Services/fonctions";

function Login() {
  const navigate = useNavigate();
  const history = useHref();
  const myRef = useRef(null);
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  function handleHref() {
    myRef.current.setAttribute(
      "href",
      process.env.REACT_APP_API_CREATE_URl_RESET
    );
  }

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  document.title = "Login";

  return (
    <Fragment>
      <div>
        <Navbar />
        <BgHeader heading="OUVERTURE DE SESSION" />
      </div>
      {loading ? (
        <div className="CircularArea">
          <MyCircularProgress />
        </div>
      ) : (
        <div className="containerLogin">
          <div className="pageLogin">
            <div className="contenairLoginForm">
              <div className="imageLogin">
                <img src={carLogin} alt="car"></img>
              </div>

              <div className="formLogin">
                <div className="infoLogin">
                  <h2>Se connecter</h2>
                  <span>
                    Des économies allant jusqu'à 15 % grâce à nos solutions de
                    location de voitures.
                  </span>
                </div>
                <div className="formLoginGroup">
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    onSubmit={async (values) => {
                      setLoading(true);
                      const result = await login(values, setLoading);
                      if (result.status === 200) {
                        notify("Vous etes connecté");
                        navigate("/");
                      }
                    }}
                  >
                    {({ values, handleBlur, handleChange, handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="inputField">
                          <FaUser className="iconLogin" />
                          <input
                            type="email"
                            placeholder="Entré votre email"
                            required
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>

                        <div className="inputField">
                          <FaLock className="iconLogin" />
                          <input
                            type="password"
                            placeholder="Entré mot de passe"
                            required
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>

                        <div className="checkbox-text">
                          <div className="checkbox-content">
                            <input type="checkbox" />
                            <span>Se souvenir de moi</span>
                          </div>
                          <a
                            ref={myRef}
                            onClick={async () => {
                              handleHref();
                            }}
                            style={{ color: "#e2ad2b", cursor: "pointer" }}
                          >
                            Mot de passe oublié.
                          </a>
                        </div>

                        <div className="inputField button">
                          <input type="submit" value="se connecter" />
                        </div>
                        <div className="signUpForm">
                          <span>Vous n'avez pas de compte? </span>
                          <Link to={"/register"} className="btnRegister">
                            Cliquez-ici
                          </Link>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <Footer />
      </div>
    </Fragment>
  );
}

export default Login;
