import { Divider, TextField } from "@mui/material";
import React from "react";
import "./SectionFiltresCollection.css";
import Checkbox from "@mui/material/Checkbox";
import SelectTypeFilter from "../../UI/Inputs/SelectTypeFilter/SelectTypeFilter";
import SliderRange from "../SliderRange/SliderRange";
import { AllTypesCars, getAllTypesCars } from "../../Services/Services";
import SelectTypeVoiture from "../SelectTypeVoiture/SelectTypeVoiture";

export default function SectionFiltresCollection({
  handleCheckedValues2,
  handleCheckedValues3,
  handleCheckedValues4,
  handleCheckedValues5,
  Filter,
  annee,
  setAnnee,
  couleur,
  setCouleur,
  checkedPlaces,
  setCheckedPlaces,
  setFilteredCars,
  uniqueCarburant,
  uniqueBoite,
  uniqueCouleurs,
  unique,
  setCars,
  allCars,
  type,
  typeModele,
  typeCarburant,
  uniqueAnnees,
  typeboite,
  informationsReservation,
  setType,
  setInformationsReservation,
  setTypeBoite,
  setTypeCarburant,
  setTypeModele,
  isCheckbox2Checked,
  isCheckbox3Checked,
  isCheckbox4Checked,
  isCheckbox5Checked,
}) {
  const options = [
    "Siège enfant",
    "Climatisation",
    "Services de chauffeurs",
    "Équipement d'hiver",
    "Système audio Premium",
  ];
  const FilterMarque = (e) => {};
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  React.useEffect(() => {
    AllTypesCars(setType);
  }, []);
  return (
    <section id="filtres-collections">
      <div id="section-filtre-header">FILTRES</div>
      <div id="section-filtres">
        <form action="">
          <div>
            <label htmlFor="" className="prix-label">
              PRIX
            </label>
            <SliderRange
              informationsReservation={informationsReservation}
              setInformationsReservation={setInformationsReservation}
              color="#000"
            />
          </div>
          <label
            htmlFor=""
            className="choix-nombre"
            style={{ fontWeight: "bold" }}
          >
            Nombre de portes :
          </label>
          <div className="partie-checkbox">
            <div className="groupe-droite">
              <div className="groupe-checkbox">
                <Checkbox
                  {...label}
                  onChange={handleCheckedValues2}
                  value="2"
                  checked={isCheckbox2Checked}
                />
                <label htmlFor="" className="choix-nombre">
                  2
                </label>
              </div>
              <div className="groupe-checkbox">
                <Checkbox
                  {...label}
                  onChange={handleCheckedValues3}
                  value="3"
                  checked={isCheckbox3Checked}
                />
                <label htmlFor="" className="choix-nombre">
                  3
                </label>
              </div>
            </div>

            <div className="groupe-gauche">
              <div className="groupe-checkbox">
                <Checkbox
                  {...label}
                  onChange={handleCheckedValues4}
                  value="4"
                  checked={isCheckbox4Checked}
                />
                <label htmlFor="" className="choix-nombre">
                  4
                </label>
              </div>

              <div className="groupe-checkbox">
                <Checkbox
                  {...label}
                  onChange={handleCheckedValues5}
                  value="5"
                  checked={isCheckbox5Checked}
                />
                <label htmlFor="" className="choix-nombre">
                  5
                </label>
              </div>
            </div>
          </div>

          <SelectTypeVoiture
            options={type}
            titreSelect="Type de voiture"
            typeVoiture={typeModele}
            setTypeVoiture={setTypeModele}
            handleChange={FilterMarque}
          />
          <SelectTypeFilter
            options={uniqueBoite}
            titreSelect="Type de boite de vitesse"
            typeVoiture={typeboite}
            setTypeVoiture={setTypeBoite}
          />
          <SelectTypeFilter
            options={uniqueCarburant}
            titreSelect="Type de carburant"
            typeVoiture={typeCarburant}
            setTypeVoiture={setTypeCarburant}
          />

          <SelectTypeFilter
            options={uniqueAnnees}
            titreSelect="Année"
            typeVoiture={annee}
            setTypeVoiture={setAnnee}
          />
          <SelectTypeFilter
            options={uniqueCouleurs}
            titreSelect="Couleurs"
            typeVoiture={couleur}
            setTypeVoiture={setCouleur}
          />
          {/* <div className="options-additionnelles">
            <label
              htmlFor=""
              className="options"
              style={{ fontWeight: "bold" }}
            >
              Options additionnelles :
            </label>
            {options.map((option, index) => (
              <div key={index}>
                <Checkbox {...label} />
                <label htmlFor="" style={{ color: "#979797" }}>
                  {option}
                </label>
              </div>
            ))}
          </div>*/}

          <Divider />

          <button
            type="submit"
            id="section-filtre-footer"
            onClick={(e) => {
              e.preventDefault();
              setFilteredCars(Filter);
            }}
          >
            APPLIQUER LES FILTRES
          </button>
        </form>
      </div>
    </section>
  );
}
