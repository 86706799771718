import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Bgheader from "../../Components/BgHeader";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import TableReservation from "../../UI/TableReservation/TableReservation";
import { AllReservatio } from "../../Services/Services";
import { useAuthContext } from "../../Contextes/AuthProvider";
import MyCircularProgress from "../../UI/CircularProgress/CircularProgress";
import "./HistoriqueReservation.css";

const HistoriqueReservation = () => {
  const [cars, setCars] = useState([]);
  const [userId, setUser_id] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  React.useEffect(() => {
    AllReservatio(setCars, localStorage.getItem("user_id"), setLoading);
  }, []);
  document.title = "Historique";
  return (
    <Fragment>
      <div>
        <Navbar />
        <Bgheader heading="HISTORIQUE DE RESERVATION" />
      </div>
      {loading ? (
        <div className="CircularArea">
          <MyCircularProgress />
        </div>
      ) : (
        <div className="ContainerHistoriqueReservation">
          <TableReservation allReservations={cars} />
        </div>
      )}

      <div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default HistoriqueReservation;
