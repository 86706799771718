import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AuthProvider from "./Contextes/AuthProvider";

// Charger les variables d'environnement en fonction du mode d'exécution (développement ou production)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ToastContainer />

    <App />
  </BrowserRouter>
);
