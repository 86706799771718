import "./BgStyle.css";
import React from "react";
import logo from "../assets/voiture.png";
import logo1 from "../assets/bleu.jpg";
import beautifull1 from "../assets/beautifull1.jpg";
import beautifull2 from "../assets/beautifull2.jpg";
import voiture from "../assets/voiture.png";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const backgroundImage = () => {
  return (
    <div className="background-img">
      <div className="bg-img">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
          style={{ position: "relative", top: "0" }}
        >
          <div
            className="carousel-indicators indicators-carroussel"
            style={{ position: "absolute", top: "75vh" }}
          >
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
              style={{
                width: "100px",
                height: "1vh",
                backgroundColor: "#e2ad2b",
              }}
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
              style={{
                width: "100px",
                height: "1vh",
                backgroundColor: "#e2ad2b",
              }}
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
              style={{
                width: "100px",
                height: "1vh",
                backgroundColor: "#e2ad2b",
              }}
            ></button>
          </div>
          <div className="carousel-inner" style={{ height: "100vh" }}>
            <div
              className="carousel-item active"
              style={{
                backgroundImage: `url(${logo})`,
                width: "100%",
                height: "100vh",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
            <div
              className="carousel-item"
              style={{
                backgroundImage: `url(${beautifull1})`,
                width: "100%",
                height: "100vh",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
            <div
              className="carousel-item"
              style={{
                backgroundImage: `url(${beautifull2})`,
                width: "100%",
                height: "100vh",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            ></div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          ></button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          ></button>
        </div>
      </div>

      <div className="content">
        <h1>
          Louer votre vehicule ce
          <br />
          n'est pas une question de
          <br /> chance.
        </h1>

        <div className="btnReservation">
          <Link to={"/nos-vehicules"} className="btnRes">
            {" "}
            Réserver une voiture{" "}
            <FaArrowRight size={25} style={{ width: "15px", marginLeft: "4px" }} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default backgroundImage;
