import React, { Fragment, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BgHeader from "../BgHeader";
import Navbar from "../Navbar";
import Footer from "../Footer";
import "./editProfil.css";
import { FaFileExport, FaFileInvoiceDollar, FaUser } from "react-icons/fa";
import { CompteClient, EditClient, Logout } from "../../Services/Services";
import { useAuthContext } from "../../Contextes/AuthProvider";
import { EditValidation } from "./EditValidation";
import { Formik } from "formik";
import { notify } from "../../Services/fonctions";

const EditProfil = () => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const { user, setUser } = useAuthContext();
  const navigate = useNavigate();
  const [token, setToken] = React.useState(
    localStorage.getItem("token") ? localStorage.getItem("token") : null
  );
  document.title = "Edit Profil";
  return (
    <Fragment>
      <div>
        <Navbar />
        <BgHeader heading="MON COMPTE " />
      </div>

      <div className="ContainerEditProfil">
        <div className="section-edit">
          <div className="details-edit">
            <h2> Détails du compte :</h2>
            <div className="profile-user-edit">
              <FaUser className="icon-profile-compte-edit" />
              <div>
                <span>{user.nom}</span>
              </div>
            </div>

            <div className="profile-user-edit">
              <FaFileExport className="icon-profile-compte-edit" />
              <div>
                <Link to={"/profil-reservation"}>
                  <span>Profil des réservations</span>
                </Link>
              </div>
            </div>

            <div className="profile-user-edit">
              <FaFileInvoiceDollar className="icon-profile-compte-edit" />
              <div>
                <Link to={"/historique-Reservation"}>
                  <span>Historique des réservations</span>
                </Link>
              </div>
            </div>
            <div className="profile-user-edit d">
              <Link to={"#"}>
                <span
                  onClick={async () => {
                    const result = await Logout(setToken);
                    if (result.status === 200) {
                      navigate("/");
                    }
                  }}
                >
                  Deconnexion
                </span>
              </Link>
              <span class="Path-edit"></span>
            </div>
          </div>

          <div className="containerEdit-profil">
            <div className="infoEdit-profil">
              <h2>Edit profil</h2>
            </div>
            <div className="formEditGroup">
              <Formik
                validationSchema={EditValidation}
                enableReinitialize={true}
                initialValues={{
                  nom: user.nom || "",
                  email: user.email || "",
                  telephone: user.telephone || "",
                  pays: user.pays || "",
                  adresse: user.adresse || "",
                }}
                onSubmit={async (values) => {
                  const result = await EditClient(
                    {
                      nom: values.nom,
                      email: values.email,
                      telephone: values.telephone,
                      pays: values.pays,
                      adresse: values.adresse,
                    },
                    user.id
                  );

                  if (result.status === 200 || result.status === 201) {
                    setUser({
                      ...user,
                      nom: values.nom,
                      email: values.email,
                      telephone: values.telephone,
                      pays: values.pays,
                      adresse: values.adresse,
                    });
                    notify("Votre compte a bien été modifié");
                    navigate("/compte");
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="formInputEdit">
                      <div className="inputFieldEdit">
                        <input
                          type="text"
                          placeholder="Entré votre nom"
                          value={values.nom}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="nom"
                        />
                        <p
                          style={{
                            color: "red",
                            fontSize: 13,
                            marginBottom: "2vh",
                          }}
                        >
                          {errors.nom && touched.nom && errors.nom}
                        </p>
                      </div>

                      <div className="inputFieldEdit">
                        <input
                          type="email"
                          placeholder="Entré votre email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="email"
                        />
                        <p
                          style={{
                            color: "red",
                            fontSize: 13,
                            marginBottom: "2vh",
                          }}
                        >
                          {errors.email && touched.email && errors.email}
                        </p>
                      </div>
                    </div>

                    <div className="formInputEdit">
                      <div className="inputFieldEdit">
                        <input
                          type="text"
                          placeholder="Entré votre numéro"
                          required
                          value={values.telephone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="telephone"
                        />
                        <p
                          style={{
                            color: "red",
                            fontSize: 13,
                            marginBottom: "2vh",
                          }}
                        >
                          {errors.telephone &&
                            touched.telephone &&
                            errors.telephone}
                        </p>
                      </div>

                      <div className="inputFieldEdit">
                        <input
                          type="text"
                          placeholder="Pays"
                          required
                          value={values.pays}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="pays"
                        />
                        <p
                          style={{
                            color: "red",
                            fontSize: 13,
                            marginBottom: "2vh",
                          }}
                        >
                          {errors.pays && touched.pays && errors.pays}
                        </p>
                      </div>
                    </div>
                    <div className="formInputEdit">
                      <div className="inputFieldEdit adress">
                        <input
                          type="text"
                          placeholder="Entré votre adresse"
                          required
                          value={values.adresse}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="adresse"
                        />
                        <p
                          style={{
                            color: "red",
                            fontSize: 13,
                            marginBottom: "2vh",
                          }}
                        >
                          {errors.adresse && touched.adresse && errors.adresse}
                        </p>
                      </div>
                    </div>

                    <div className="inputFieldEdit button">
                      <input type="submit" value="Modifier le profil" />
                    </div>

                    <div className="retourPageCompte">
                      <Link to={"/compte"}>Retour</Link>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default EditProfil;
