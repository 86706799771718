import React from "react";
import { AllReservatio, CompteClient, login } from "../Services/Services";
export const AuthContext = React.createContext();
export const useAuthContext = () => React.useContext(AuthContext);
export default function AuthProvider({ children }) {
  const [user, setUser] = React.useState({});
  const [cars, setCars] = React.useState([]);
  const [userId, setUser_id] = React.useState(
    localStorage.getItem("user_id") && localStorage.getItem("user_id")
  );
  React.useEffect(() => {
    CompteClient(userId, setUser);
    AllReservatio(setCars, userId);
  }, [userId]);

  return (
    <AuthContext.Provider value={{ user, cars, setUser, setUser_id, userId }}>
      {children}
    </AuthContext.Provider>
  );
}
