import "./ReservationStyle.css";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import React, { Fragment } from "react";
import Bgheader from "../Components/BgHeader";
import FormulaireReservation from "../Components/FormulaireReservation/FormulaireReservation";
import CarPromotionCard from "../Components/CarPromotionCard/CarPromotionCard";
import MyProgressBar from "../UI/MyProgressBar/MyProgressBar";
import { useLocation } from "react-router-dom";

const Reservation = () => {
  const car = localStorage.getItem("voiture")
    ? JSON.parse(localStorage.getItem("voiture"))
    : {};
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  document.title = "Réservation";
  return (
    <Fragment>
      <div>
        <Navbar />
        <Bgheader heading="RESERVATION" />
      </div>
      <div className="containerLogin">
        <div id="carte-auto">
          <CarPromotionCard car={car} />
        </div>

        {car.disponible === "oui" ? (
          <FormulaireReservation />
        ) : (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Vous ne pouvez pas reserver cette voiture. Elle es actuellement
            indisponible
          </span>
        )}
      </div>
      <div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Reservation;
