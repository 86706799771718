import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import BgHeader from "../BgHeader";
import Navbar from "../Navbar";
import Footer from "../Footer";
import "./forget.css";
import { SendEmail } from "../../Services/Services";

function PasswordForget() {
  const [email, setEmail] = React.useState("lifa96.kg@gmail.com");
  const handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    console.log(email);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    SendEmail(email);
  };

  return (
    <Fragment>
      <div>
        <Navbar />
        <BgHeader heading="OUVERTURE DE SESSION" />
      </div>

      <div className="containerForgetPassword">
        <div className="pagePwd">
          <div className="formForget">
            <div className="infoForget">
              <h2>Mot de passe oublié</h2>
            </div>
            <div className="formForgetGroup">
              <form>
                <div className="inputFieldForget">
                  <input
                    type="email"
                    placeholder="Entré votre email"
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="inputFieldForget button">
                  <input
                    type="submit"
                    value="Soumettre email"
                    onClick={handleSubmit}
                  />
                </div>
                <div className="signUpFormForget">
                  <span>Vous n'avez pas de compte? </span>
                  <Link to={"/register"} className="btnRegisterForget">
                    Cliquez-ici
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </Fragment>
  );
}

export default PasswordForget;
