import * as React from "react";
import { Avatar, Divider } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./Modal.css";

export default function ModalCommentaires({
  open,
  handleOpen,
  handleClose,
  avisCar,
  setOpen,
}) {
  const [scroll, setScroll] = React.useState("paper");
  const tabNumbers = [0, 1, 2, 3, 4];
  let numbers = [];
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <div className="dialog-boite">
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title" className="dialog-boite">
          Tous les commentaires
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {avisCar.map((avis, index) => (
              <div key={index}>
                <div className="reviews">
                  <div className="reviews-ensemble">
                    <Avatar
                      sx={{ bgcolor: "orange", width: "60px", height: "60px" }}
                    >
                      {avis.nom[0]}
                    </Avatar>
                    <div className="reviews-details">
                      <span className="titre-reviews">{avis.nom}</span>
                      <div className="reviews-starts">
                        <div style={{ display: "none" }}>
                          {
                            (numbers = tabNumbers.filter(
                              (number) => number < avis.etoiles
                            ))
                          }
                        </div>

                        {numbers.map((number, index) => (
                          <StarIcon key={index} style={{ color: "#e2ad2b" }} />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <span className="reviews-texte">{avis.commentaire}</span>
                  </div>
                </div>
                <Divider />
              </div>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "#e2ad2b" }}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
