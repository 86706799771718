import React from "react";
import logo from "../../assets/voiture.png";
import logo1 from "../../assets/bleu.jpg";
import luxe from "../../assets/mercedes.jpg";
import { tab } from "@testing-library/user-event/dist/tab";

export default function SectionPub({ tabElements }) {
  //recherche des images a afficher dans le tableau des 5 dernieres images
  const image1 = tabElements[0] !== undefined ? tabElements[0].imageUrl : "";
  const image2 = tabElements[1] !== undefined ? tabElements[1].imageUrl : "";
  const image3 = tabElements[2] !== undefined ? tabElements[2].imageUrl : "";
  const image4 = tabElements[3] !== undefined ? tabElements[3].imageUrl : "";
  const image5 = tabElements[4] !== undefined ? tabElements[4].imageUrl : "";

  return (
    <div className="section-pub">
      <div
        id="indicateur"
        className="carousel slide"
        data-bs-ride="carousel"
        style={{ position: "relative", top: "0" }}
      >
        <div
          className="carousel-indicators"
          style={{ position: "absolute", top: "0vh" }}
        >
          <button
            type="button"
            data-bs-target="#indicateur"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
            style={{
              width: "100px",
              height: "1vh",
              backgroundColor: "#e2ad2b",
            }}
          ></button>
          <button
            type="button"
            data-bs-target="#indicateur"
            data-bs-slide-to="1"
            aria-label="Slide 2"
            style={{
              width: "100px",
              height: "1vh",
              backgroundColor: "#e2ad2b",
            }}
          ></button>
          <button
            type="button"
            data-bs-target="#indicateur"
            data-bs-slide-to="2"
            aria-label="Slide 3"
            style={{
              width: "100px",
              height: "1vh",
              backgroundColor: "#e2ad2b",
            }}
          ></button>
          <button
            type="button"
            data-bs-target="#indicateur"
            data-bs-slide-to="3"
            aria-label="Slide 4"
            style={{
              width: "100px",
              height: "1vh",
              backgroundColor: "#e2ad2b",
            }}
          ></button>
          <button
            type="button"
            data-bs-target="#indicateur"
            data-bs-slide-to="4"
            aria-label="Slide 5"
            style={{
              width: "100px",
              height: "1vh",
              backgroundColor: "#e2ad2b",
            }}
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div>
              <div
                style={{
                  width: "100%",
                  height: "40vh",

                  color: "#000 !important",
                }}
              >
                <img
                  src={image1}
                  alt=""
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "auto",
                  }}
                />
              </div>

              <h2
                className="text-center titre-caroussel mt-3"
                style={{ color: "#000 !important" }}
              >
                {tabElements[0] !== undefined && tabElements[0].nom}
              </h2>
              <span
                className="tarif-carroussel"
                style={{
                  color: "#e2ad2b",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {tabElements[0] !== undefined && tabElements[0].prix}€/jour
              </span>
              <p
                className="text-center"
                style={{ display: "block", width: "50%", margin: "auto" }}
              >
                {tabElements[0] !== undefined && tabElements[0].modele}&nbsp;
                {tabElements[0] !== undefined && tabElements[0].energie},&nbsp;
                {tabElements[0] !== undefined && tabElements[0].nbrPlace} places
                &nbsp;
                {tabElements[0] !== undefined && tabElements[0].boite}&nbsp;
                <span>
                  Disponible en &nbsp;
                  {tabElements[0] !== undefined && tabElements[0].couleur}{" "}
                </span>
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <div style={{}}>
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                }}
              >
                <img
                  src={image2}
                  alt=""
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "auto",
                  }}
                />
              </div>

              <h2 className="text-center titre-caroussel mt-3">
                {tabElements[1] !== undefined && tabElements[1].nom}
              </h2>
              <span
                className="tarif-carroussel"
                style={{
                  color: "#e2ad2b",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {tabElements[1] !== undefined && tabElements[1].prix}€/jour
              </span>
              <p
                className="text-center"
                style={{ display: "block", width: "50%", margin: "auto" }}
              >
                {tabElements[1] !== undefined && tabElements[1].modele}&nbsp;
                {tabElements[1] !== undefined && tabElements[1].energie},&nbsp;
                {tabElements[1] !== undefined && tabElements[1].nbrPlace}{" "}
                places&nbsp;
                {tabElements[1] !== undefined && tabElements[1].boite}&nbsp;
                <span>
                  Disponible en &nbsp;
                  {tabElements[1] !== undefined && tabElements[1].couleur}{" "}
                </span>
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <div style={{}}>
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                }}
              >
                <img
                  src={image3}
                  alt=""
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "auto",
                  }}
                />
              </div>

              <h2 className="text-center titre-caroussel mt-3">
                {tabElements[2] !== undefined && tabElements[2].nom}
              </h2>
              <span
                className="tarif-carroussel"
                style={{
                  color: "#e2ad2b",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {tabElements[2] !== undefined && tabElements[2].prix}€/jour
              </span>
              <p
                className="text-center"
                style={{ display: "block", width: "50%", margin: "auto" }}
              >
                {tabElements[2] !== undefined && tabElements[2].modele}&nbsp;
                {tabElements[2] !== undefined && tabElements[2].energie},&nbsp;
                {tabElements[2] !== undefined && tabElements[2].nbrPlace}{" "}
                places&nbsp;
                {tabElements[2] !== undefined && tabElements[2].boite}&nbsp;
                <span>
                  Disponible en &nbsp;
                  {tabElements[2] !== undefined && tabElements[2].couleur}{" "}
                </span>
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <div style={{}}>
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                }}
              >
                <img
                  src={image4}
                  alt=""
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "auto",
                  }}
                />
              </div>

              <h2 className="text-center titre-caroussel mt-3">
                {tabElements[3] !== undefined && tabElements[3].nom}
              </h2>
              <span
                className="tarif-carroussel"
                style={{
                  color: "#e2ad2b",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {tabElements[3] !== undefined && tabElements[3].prix}€/jour
              </span>
              <p
                className="text-center"
                style={{ display: "block", width: "50%", margin: "auto" }}
              >
                {tabElements[3] !== undefined && tabElements[3].modele}&nbsp;
                {tabElements[3] !== undefined && tabElements[3].energie},&nbsp;
                {tabElements[3] !== undefined && tabElements[3].nbrPlace}{" "}
                places&nbsp;
                {tabElements[3] !== undefined && tabElements[3].boite}&nbsp;
                <span>
                  Disponible en &nbsp;
                  {tabElements[3] !== undefined && tabElements[3].couleur}{" "}
                </span>
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <div style={{}}>
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                }}
              >
                <img
                  src={image5}
                  alt=""
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "auto",
                  }}
                />
              </div>

              <h2 className="text-center titre-caroussel mt-3">
                {tabElements[4] !== undefined && tabElements[4].nom}
              </h2>
              <span
                className="tarif-carroussel"
                style={{
                  color: "#e2ad2b",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {tabElements[4] !== undefined && tabElements[4].prix}€/jour
              </span>
              <p
                className="text-center"
                style={{ display: "block", width: "50%", margin: "auto" }}
              >
                {tabElements[4] !== undefined && tabElements[4].modele}&nbsp;
                {tabElements[4] !== undefined && tabElements[4].energie},&nbsp;
                {tabElements[4] !== undefined && tabElements[4].nbrPlace}{" "}
                places&nbsp;
                {tabElements[4] !== undefined && tabElements[4].boite}&nbsp;
                <span>
                  Disponible en &nbsp;
                  {tabElements[4] !== undefined && tabElements[4].couleur}{" "}
                </span>
              </p>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        ></button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        ></button>
      </div>
    </div>
  );
}
