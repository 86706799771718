import { Divider } from "@mui/material";
import React from "react";
import { getOneCar } from "../../Services/Services";
import "./Affiche2.css";
export default function Affiche2({ voiture_id, type, row }) {
  const [oneCar, setOne] = React.useState([]);
  React.useEffect(() => {
    getOneCar(setOne, voiture_id);
  }, []);
  return (
    <div className="affiche2">
      <div>
        <img
          src={oneCar && oneCar.imageUrl}
          alt="voiture"
          className="image_voiture"
        />
        <div className="informations">
          <div className="rangement">
            {" "}
            <span>Marque:</span>
            <span>{oneCar && oneCar.marque}</span>
          </div>
          <div className="rangement">
            {" "}
            <span>Modele:</span>
            <span>{oneCar && oneCar.modele}</span>
          </div>
          <div className="rangement">
            {" "}
            <span>Date debut:</span>
            <span>{row && row.dateDebut}</span>
          </div>
          <div className="rangement">
            <span>Prix Total:</span>
            <span>{row && row.total}</span>
          </div>
          <div className="rangement">
            <span>Type de payement:</span>
            <span>{type}</span>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
}
