import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import Geocode from "react-geocode";

const containerStyle = {
  width: "96%",
  height: "400px",
  marginTop: "3vh",
};
const marqueurs = [["Agence Cercy", 43.5766526934, 1.43375993162]];
Geocode.setApiKey(process.env.REACT_APP_API_MAPS);
function addMarker(map) {
  const markers = marqueurs.map(([name, lat, lng]) => {
    const marqueur = new window.google.maps.Marker({ position: { lat, lng } });
    return marqueur;
  });
}

function MyComponent({ center }) {
  const zoom = 10;
  const [infos, setInfos] = React.useState({ lat: 48.856614, lng: 2.3522219 });
  React.useEffect(() => {
    const cords = Geocode.fromAddress(center).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setInfos({ lat: lat, lng: lng });
      },
      (error) => {
        console.log("impossible d'acceder");
      }
    );
  }, [center]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_API_MAPS,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    addMarker(map);
    map.setZoom(zoom);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={infos}
      onLoad={onLoad}
      zoom={1}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default MyComponent;
