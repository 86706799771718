import React from "react";
import StripeContainer from "../../Components/Stripe/StripeContainer";

export default function () {
  return (
    <div>
      <StripeContainer />
    </div>
  );
}
