import React from "react"
import "./aide.css"
import { FaFacebookF, FaRoute, FaTwitter, FaInstagramSquare, FaDollarSign, FaApple, FaGooglePlay } from "react-icons/fa"

import { MdOutlineSecurity } from "react-icons/md"
import { IoMdOptions } from "react-icons/io"
import { GrShieldSecurity, GrContactInfo } from "react-icons/gr"
import carLogin from "../../assets/carLogin.png"
import section from "../../assets/section.png"
import { GiCardPick } from "react-icons/gi"
import { BsFillPersonLinesFill } from "react-icons/bs"
function Aide() {
  return (
    <>
      <div className="contain_section">
        <div className="_paragraph">
          <h3>VOYAGER AVEC UN CHAUFFEUR PERSONNEL</h3>
          <p>Que ce soit pour des raisons professionnelles ou privées, dans un style économique ou de première classe. Avec SIXT ride, vous bénéficiez de services de transport adaptés à vos besoins. En vous connectant à notre plateforme, vous bénéficiez d'un choix de différentes catégories de réservation et de la plus grande couverture de services de transport dans le monde entier. Téléchargez l'application SIXT dès maintenant!</p>
        </div>
        <div className="_image">
          <img src={carLogin} alt="car"></img>
        </div>
      </div>
      <div className="content-103">
        <div className="container_about">
          <div className="row_about">
            <h2 className="service-title">PLUS D'UN MILLION DE CONDUCTEURS DANS LE MONDE.</h2>
            <h4 className="service-description">RÉSERVEZ DES TRAJETS À TOUT MOMENT, OÙ QUE VOUS SOYEZ.</h4>
            <div className="container_post">
              <div className="service-post">
                <div className="service-content">
                  <div className="service-icon">
                    <FaRoute />
                  </div>
                  <h3 className="service-title">TRAJETS PROGRAMMÉS OU À LA DEMANDE</h3>
                  <p className="service-description">Seitan brunch meh, food truck Wes Anderson quinoa XOXO readymade gastropub gluten-free heirloom wolf skateboard.</p>
                </div>
                <div className="service-hover"></div>
              </div>

              <div className="service-post">
                <div className="service-content">
                  <div className="service-icon">
                    <FaDollarSign />
                  </div>
                  <h3 className="service-title">PAIEMENT SANS ESPÈCES</h3>
                  <p className="service-description">Seitan brunch meh, food truck Wes Anderson quinoa XOXO readymade gastropub gluten-free heirloom wolf skateboard.</p>
                </div>
                <div className="service-hover"></div>
              </div>

              <div className="service-post">
                <div className="service-content">
                  <div className="service-icon">
                    <IoMdOptions />
                  </div>
                  <h3 className="service-title">Creative Marketing</h3>
                  <p className="service-description">Seitan brunch meh, food truck Wes Anderson quinoa XOXO readymade gastropub gluten-free heirloom wolf skateboard.</p>
                </div>
                <div className="service-hover"></div>
              </div>
            </div>
            <div className="container_post">
              <div className="service-post">
                <div className="service-content">
                  <div className="service-icon">
                    <GiCardPick />
                  </div>
                  <h3 className="service-title">CHOIX DU VÉHICULE</h3>
                  <p className="service-description">Seitan brunch meh, food truck Wes Anderson quinoa XOXO readymade gastropub gluten-free heirloom wolf skateboard.</p>
                </div>
                <div className="service-hover"></div>
              </div>

              <div className="service-post">
                <div className="service-content">
                  <div className="service-icon">
                    <MdOutlineSecurity />
                  </div>
                  <h3 className="service-title">SÉCURITÉ</h3>
                  <p className="service-description">Seitan brunch meh, food truck Wes Anderson quinoa XOXO readymade gastropub gluten-free heirloom wolf skateboard.</p>
                </div>
                <div className="service-hover"></div>
              </div>

              <div className="service-post">
                <div className="service-content">
                  <div className="service-icon">
                    <BsFillPersonLinesFill />
                  </div>
                  <h3 className="service-title">TOUT EN UN SEUL ENDROIT</h3>
                  <p className="service-description">Seitan brunch meh, food truck Wes Anderson quinoa XOXO readymade gastropub gluten-free heirloom wolf skateboard.</p>
                </div>
                <div className="service-hover"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contain_section">
        <div>
          <img className="_image_section" src={section} alt="car"></img>
        </div>
        <div className="_download_apk">
          <h2 className="service-title">SOYEZ MOBILE DANS LE MONDE ENTIER AVEC SIXT</h2>
          <p className="service-apk">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Praesentium nemo consequuntur amet esse eum tempore quia necessitatibus, deserunt voluptates, harum corporis suscipit. Officiis, sequi praesentium reiciendis animi fugiat debitis quidem!</p>
        </div>
      </div>
      <div className="social_">
        <h3 className="_download_paragraph">TÉLÉCHARGEZ L'APP SIXT</h3>
        <div className="_input_download">
          {" "}
          <FaApple className="Icon_download" />
          <span className="_input_l">App Store</span> <FaGooglePlay className="Icon_download_l" />
          <span className="_input_r">Google Play</span>
        </div>
        <h4>SUIVEZ-NOUS</h4>
        <div className="_social_network">
          <div className="_social">
            <FaFacebookF />
          </div>
          <div className="_social">
            <FaTwitter />
          </div>
          <div className="_social">
            <FaInstagramSquare />
          </div>
        </div>
      </div>
    </>
  )
}

export default Aide
