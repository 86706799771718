import React from "react";
import CarousselAvis from "../CarousselAvis/CarousselAvis";
import "./SectionAvis.css";
export default function SectionAvis() {
  return (
    <section className="section-avis">
      <div className="titre-section-avis">
        <div className="tiret-droit"></div>
        <h2 className=" titre-avis">L'AVIS DE NOS CLIENT</h2>
        <div className="tiret-gauche"></div>
      </div>
      {/*<h2 className=" titre-avis"></h2>*/}
      <CarousselAvis />
    </section>
  );
}
