import React from "react";
import "../faq.css";
import "./infos.css";
import { FaSearch, FaPlus } from "react-icons/fa";

function InfoBeeLoc() {
  return (
    <div className="conatiner_info">
      <div className="head_info">
        <h3>Information sur BeeLoc :</h3>
        <p>
          Bienvenue chez Good Loc, l'agence de location de voiture de référence
          en France ! Nous sommes une entreprise de location de voiture basée en
          France, spécialisée dans la location de voitures pour les particuliers
          et les entreprises. Good Loc propose un large choix de voitures de
          qualité, allant des petits modèles économiques aux voitures de luxe
          haut de gamme, pour répondre à tous vos besoins. Si vous avez des
          questions ou besoin d'informations supplémentaires, consultez notre
          section FAQ où vous trouverez les réponses aux questions les plus
          courantes. Si vous ne trouvez pas la réponse à votre question,
          n'hésitez pas à nous contacter directement. Notre équipe est là pour
          répondre à toutes vos interrogations et vous aider dans vos démarches.
        </p>
      </div>
      <div className="category_question">
        <details open>
          <summary>Comment annuler ou modifier une réservation ?</summary>
          <p>
            Vous pouvez annuler ou modifier votre réservation : - Allez dans la
            rubrique "Mes réservations", annulez jusqu'à 24h à l'avance - Ou
            bien auprès de votre agence de départ : à tout moment
          </p>
        </details>

        <details>
          <summary> Y’a t'il des frais d’annulation ?</summary>
          <p>
            Y’a t'il des frais d’annulation ? Pour toute annulation effectuée au
            moins 48 heures avant la date de départ de la location, le montant
            total de la réservation sera remboursé sur le compte de la carte
            bancaire utilisée pour la réservation. Cependant, veuillez noter
            qu'au-delà de ce délai, des frais d'annulation de 50 € ou 100 € TTC
            (selon la catégorie de véhicule) seront retenus par
          </p>
        </details>
      </div>
      <div className="head_info">
        <p></p>
      </div>
    </div>
  );
}

export default InfoBeeLoc;
