import "./ReservationCardStyle.css";
import React from "react";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import SelectTypeVoiture from "../SelectTypeVoiture/SelectTypeVoiture";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import SliderRange from "../SliderRange/SliderRange";
const ReservationCard = ({ uniqueCars }) => {
  const [typeVoiture, setTypeVoiture] = React.useState("marseille");
  const today = new Date().toISOString().split("T")[0];
  const minTime = new Date().toTimeString().slice(0, 5);
  const [informationsReservation, setInformationsReservation] = React.useState({
    lieu: "",
    dateRamassage: "",
    heureRamassage: "",
    lieuRetourVoiture: "",
    dateRetour: "",
    heureRetour: "",
    service: "service avec chauffeur",
    prix: 800,
  });

  return (
    <section id="reservationCard">
      <form action="">
        <div className="inputs-fields">
          <div className="lieu">
            <label htmlFor="" className="label-lieu">
              Lieu de ramassage
            </label>
            <TextField
              placeholder="Lieu de ramassage"
              sx={{
                background: "#fff",
                borderRadius: "4px",
                outline: "none",
                border: "none",
                marginTop: "1.5vh",
              }}
              size="small"
              fullWidth={true}
              value={informationsReservation.lieu}
              onChange={(e) =>
                setInformationsReservation({
                  ...informationsReservation,
                  lieu: e.target.value,
                })
              }
            />
          </div>

          <div className="heure">
            <label htmlFor="" className="label-heure">
              Date de ramassage
            </label>
            <TextField
              type="date"
              placeholder="pays,ville ou code aéroport"
              sx={{
                background: "#fff",
                borderRadius: "4px",
                outline: "none",
                border: "none",
                marginTop: "1.5vh",
              }}
              inputProps={{
                min: today,
              }}
              size="small"
              fullWidth={true}
              value={informationsReservation.dateRamassage}
              onChange={(e) =>
                setInformationsReservation({
                  ...informationsReservation,
                  dateRamassage: e.target.value,
                })
              }
            />
          </div>

          <div className="date">
            <label htmlFor="" className="label-date">
              Heure
            </label>
            <TextField
              placeholder="Heure de ramassage"
              sx={{
                background: "#fff",
                borderRadius: "4px",
                outline: "none",
                border: "none",
                marginTop: "1.5vh",
              }}
              value={informationsReservation.heureRamassage}
              onChange={(e) =>
                setInformationsReservation({
                  ...informationsReservation,
                  heureRamassage: e.target.value,
                })
              }
              inputProps={{
                min: minTime,
              }}
              size="small"
              fullWidth={true}
              type="time"
            />
          </div>
        </div>

        <div className="inputs-fields">
          <div className="lieu">
            <label htmlFor="" className="label-lieu">
              Retourner la voiture a un endroit différent
            </label>
            <TextField
              placeholder="Lieu de retour"
              sx={{
                background: "#fff",
                borderRadius: "4px",
                outline: "none",
                border: "none",
                marginTop: "1.5vh",
              }}
              size="small"
              fullWidth={true}
              value={informationsReservation.lieuRetourVoiture}
              onChange={(e) =>
                setInformationsReservation({
                  ...informationsReservation,
                  lieuRetourVoiture: e.target.value,
                })
              }
            />
          </div>

          <div className="heure">
            <label htmlFor="" className="label-heure">
              Date de retour
            </label>
            <TextField
              type="date"
              inputProps={{
                min: today,
              }}
              placeholder="pays,ville ou code aéroport"
              sx={{
                background: "#fff",
                borderRadius: "4px",
                outline: "none",
                border: "none",
                marginTop: "1.5vh",
              }}
              value={informationsReservation.dateRetour}
              onChange={(e) =>
                setInformationsReservation({
                  ...informationsReservation,
                  dateRetour: e.target.value,
                })
              }
              size="small"
              fullWidth={true}
            />
          </div>

          <div className="date">
            <label htmlFor="" className="label-date">
              Heure
            </label>
            <TextField
              placeholder="Heure de ramassage"
              inputProps={{
                min: minTime,
              }}
              sx={{
                background: "#fff",
                borderRadius: "4px",
                outline: "none",
                border: "none",
                marginTop: "1.5vh",
              }}
              size="small"
              fullWidth={true}
              type="time"
              value={informationsReservation.heureRetour}
              onChange={(e) =>
                setInformationsReservation({
                  ...informationsReservation,
                  heureRetour: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="groupe-radios">
          <div>
            <input
              type="radio"
              name="service"
              defaultChecked
              id="aide"
              value={informationsReservation.service}
              onChange={(e) =>
                setInformationsReservation({
                  ...informationsReservation,
                  service: "sevice avec chauffeur",
                })
              }
            />
            <label htmlFor="" style={{ color: "#fff" }}>
              Service avec chauffeur
            </label>
          </div>

          <div>
            <input
              type="radio"
              name="service"
              id="service-chauffeur"
              value={informationsReservation.service}
              onChange={(e) =>
                setInformationsReservation({
                  ...informationsReservation,
                  service: "sevice sans chauffeur",
                })
              }
            />
            <label htmlFor="" style={{ color: "#fff" }}>
              Service sans chauffeur
            </label>
          </div>
          <div>
            <div
              style={{
                color: "#fff",
                marginBottom: "1vh",
                fontWeight: "bold",
                fontSize: "22px",
                width: 300,
              }}
            >
              PRIX
            </div>
            <SliderRange
              informationsReservation={informationsReservation}
              setInformationsReservation={setInformationsReservation}
              color="#fff"
            />
          </div>
        </div>
        <div style={{ color: "#fff", fontWeight: "bold" }}>
          SELECTIONNEZ LA VILLE
        </div>
        <div className="selectVoiture">
          <SelectTypeVoiture
            style={{ flex: "3" }}
            options={uniqueCars}
            typeVoiture={typeVoiture}
            setTypeVoiture={setTypeVoiture}
            titreSelect="Type"
          />
          <div
            className="boutton-reserver"
            style={{ flex: "1" }}
            onClick={() =>
              localStorage.setItem(
                "informationsReservation",
                JSON.stringify(informationsReservation)
              )
            }
          >
            <Link
              to={`/liste-voitures/${typeVoiture}`}
              className="btn-reserver"
              style={{ color: "#fff" }}
            >
              {" "}
              Réserver une voiture{" "}
              <FaArrowRight size={25} style={{ width: "12px", marginLeft: "4px" }} />
            </Link>
          </div>
        </div>
      </form>
    </section>
  );
};

export default ReservationCard;
