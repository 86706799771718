import axios from "axios";
///Nouvelle instance d'axios
export const apiInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 200000,
});
apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("Une erreur est survenue :", error.message);
    // Gérer l'erreur de manière appropriée, par exemple en affichant un message d'erreur à l'utilisateur
    return Promise.reject(error);
  }
);
