import React from "react";
import PaginationCard from "../PaginationCars/PaginationCard";
import CarPromotionCard from "../CarPromotionCard/CarPromotionCard";
import "./SectionCollectionPagination.css";
import { Box, Grid } from "@mui/material";
import MyCircularProgress from "../../UI/CircularProgress/CircularProgress";

export default function SectionCollectionPagination({ cars, loading }) {
  return (
    <Box className="collection-cars">
      {loading && <MyCircularProgress />}
      {cars.length !== 0 ? (
        cars.map((car, index) => <CarPromotionCard car={car} key={index} />)
      ) : (
        <span>Aucune voiture pour l'instant</span>
      )}
    </Box>
  );
}
