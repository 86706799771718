import { Button, TextField } from "@mui/material";
import React from "react";
import MyComponent from "../../UI/Inputs/Maps/Maps";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./SectionAgences.css";
export default function SectionAgences() {
  const [localisation, setLocalisation] = React.useState("");
  const [localisationTotale, setLocalisationTotale] = React.useState("Paris");
  return (
    <section id="nosAgences">
      <div className="details-agences">
        <div className="tiret-droit"></div>
        <h2 className=" titreAgences">NOS AGENCES EN FRANCE</h2>
        <div className="tiret-gauche"></div>
      </div>
      <div className="emplacements-agence">
        <span className="information-agences">
          NOUS DISPOSONS DE NOMBREUX SITES PRATIQUES
        </span>
        <span className="lieux">
          CERCY, TOULOUSE, ROUEN, LILLE, MARSEILLE, LYON
        </span>

        <form action="" className="recherche-agence">
          <input
            type="text"
            placeholder="Rechercher une ville"
            className="champ-recherche "
            onChange={(e) => setLocalisation(e.target.value)}
          />
          <button
            className="boutton-recherche"
            onClick={(e) => {
              e.preventDefault();
              setLocalisationTotale(localisation);
            }}
          >
            Recherche de localisation
          </button>
          <div className="icon_recherche" style={{ display: "none" }}>
            <CheckCircleIcon
              onClick={(e) => {
                e.preventDefault();
                setLocalisationTotale(localisation);
              }}
              sx={{ color: "#e2ad2b", fontSize: "30px" }}
            />
          </div>
        </form>
        <MyComponent center={localisationTotale} />
      </div>
    </section>
  );
}
